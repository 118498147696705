import "bootstrap/dist/css/bootstrap.min.css";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../breadCrumbs/navigation";
import BasicModal from "../../modals/basicModal";
import ErrorModal from "../../modals/errorModal";
import SuccessCreateModal from "../../modals/successModalCreate";
import "../vehicleModel/createVehicleModel.css";

let apigClientFactory = require('aws-api-gateway-client').default;

export default function VehicleOnboard() 
{
  const tenantId = JSON.parse(localStorage.getItem('TenantID'));

  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";
  const CURRENT_USER_TYPE = CognitoRole;
  const superAdminRole = 'superAdminRole';

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: awsRegion,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);
  
  const [open, setOpen] = useState(false);      //state to handle dialog box to display responses
  const [children, setChildren] = useState(""); //state to handle responses 
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog

  const onHandleClose =() => 
  {
    setOpen(false);   //closes the dialog box
  }

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  const [modelName, setModelName] = useState([]);
  const [fleetList, setFleetList] = useState([]);
  const [ tenantName, setTenantName ] = useState([]);
  
  const [formData, updateFormData] = useState({
    vin: "",
    model_name: "",
    fleet_name: "",
    region: "",
    version: "",
    // firmware_version: "",
    // thing_name: "",
    tenant_id: tenantId,
    // tenant_name: "",
    // primary_ecu_id: ""
  });

  const GetTenantName = async () => 
  {
    let pathTemplate = '/tenantservice/tenant'
    let method = 'GET';
    let pathParams = 
    {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = 
      {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": tenantId
        },
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        console.log("Tenant Data.....",resp)
        const Data = resp.data;
        if(Array.isArray(Data)) {
          setTenantName(Data);
        }
      });
    }
      catch (err) {
        log.error("Axios error",err);
        setOpen(true);
        setChildren(err.message);
      }
  };

  const { vin, model_name, fleet_name, region, version, tenant_name } = formData;

  const GetVehicleModels = async () => 
  {
    let pathTemplate = '/vehicleservice/vehiclemodel'
    let method = 'GET';
    let pathParams =  
    {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        }
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
   try {
    await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then((resp) => {
      const Data = resp.data.vehicle_models;
      if(Array.isArray(Data)) {
        setModelName(Data);
      }
     
    });
   }
   catch (err) {
    log.error("Axios error",err);
    log.error("Axios error",err.message);
    setOpen(true);
    setChildren(err.message);
  }
  };

  const getfleet = async () => 
  {
    let pathTemplate = '/fleetservice/fleet'
    let method = 'GET';
    let pathParams = 
    {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        }
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        const Data = resp.data.records;
        if(Array.isArray(Data)) {
          setFleetList(Data);
        }
      });
    }
      catch (err) {
        log.error("Axios error",err);
        log.error("Axios error",err.message);
        setOpen(true);
        setChildren(err.message);
      }
  };

  useEffect(() => 
  {
    GetTenantName();
    GetVehicleModels();
    getfleet();
  }, []);

  const vehicleData = async () => 
  {
    let pathTemplate = '/vehicleservice/vehicle'
    let method = 'POST';
    let pathParams = 
    {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        }
      };
       
      let body = formData;
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        if(resp.status === 200) {
          setOpenDialog(true);
        }
        else if(resp.data.status === 500){
          setOpenErrorDialog(true);
        }
        else {
          setOpen(true);
          setChildren(resp.data.msg);
        }
      });
    }
      catch (err) {
        log.error("Axios error",err);
        log.error("Axios error",err.message);
        setOpen(true);
        setChildren(err.response.data.msg || err.response.data.message);
      }
  };

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    vehicleData();
    updateFormData({
      vin: "",
      model_name: "",
      fleet_name: "",
      region: "",
      version: "",
      tenant_id: tenantId,
    });
  };

  return (
    <div>
      <Breadcrumb />
      {open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {openDialog && (
        <SuccessCreateModal
          openDialog={openDialog}
          onHandleClose={onHandleCloseDialog}
        />
      )}
      {openErrorDialog && (
        <ErrorModal
          openDialogError={openErrorDialog}
          onHandleCloseErrorDialog={onHandleCloseErrorDialog}
        />
      )}
      <h4>Onboard Vehicle</h4>
      <br></br>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8" id="model">
            <label>VIN</label>
            <input
              type="string"
              className="form-control"
              id="vin"
              value={vin}
              placeholder="Enter vin Number"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Model Name</label>
            <select
              className="form-control"
              id="model_name"
              value={model_name}
              required
              onChange={onHandleChange}
            >
              <option value={""}>Select Model Name</option>
              {modelName && modelName.map((getModelName) => (
                <option key={getModelName.id}>{getModelName.model_name}</option>
              ))}
            </select>
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Fleet Name</label>
            <select
              className="form-control"
              id="fleet_name"
              value={fleet_name}
              required
              onChange={onHandleChange}
            >
              <option value={""}>Select Fleet List</option>
              {fleetList && fleetList.map((getFleetName) => (
                <option key={getFleetName.id}>{getFleetName.fleet_name}</option>
              ))}
            </select>
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Region</label>
            <input
              type="string"
              className="form-control"
              value={region}
              id="region"
              placeholder="Enter Region Name"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Version</label>
            <input
              type="string"
              className="form-control"
              value={version}
              id="version"
              placeholder="Enter Version"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
          </div>
          {/* <br></br>
          <div className="col-sm-8">
            <label>Firmware Version</label>
            <input
              type="string"
              className="form-control"
              value={firmware_version}
              id="firmware_version"
              placeholder="Enter Firmware Version"
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Thing Name</label>
            <input
              type="string"
              className="form-control"
              value={thing_name}
              id="thing_name"
              placeholder="Enter Thing Name"
              required
              onChange={onHandleChange}
            />
          </div> */}
          <br></br>
          {CognitoRole && CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
            sensitivity: "base",
          }) === 0 && (
            <div className="col-sm-8">
              <label>Select Tenant</label>
              <select
                className="form-control"
                id="tenant_name"
                value={tenant_name}
                required
                onChange={onHandleChange}
              >
                <option value={""}>Select Tenant Name</option>
                {tenantName && tenantName.map((getTenantName) => (
                  <option key={getTenantName.id}>
                    {getTenantName.tenantName}
                  </option>
                ))}
              </select>
            </div>
          )}

          <br />
          {/* <div className="col-sm-8">
            <label>Primary ECU ID</label>
            <input
              type="string"
              className="form-control"
              value={primary_ecu_id}
              id="primary_ecu_id"
              placeholder="Enter primary ECU ID "
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br> */}
           <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
