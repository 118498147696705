import React, { useEffect, useLayoutEffect } from "react";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import bg from "./assets/logo/bg.webp"
import Router from "./route/index";
import { getConfigData } from "./services/aws";
import useCredentials from "./hook/useCredentials";
import ErrorHandler from "./components/organizer/erro-handler/error-handler";
import SnackbarComponent from "./components/melecules/snack-bar/snack-bar-component";
import queryString from "query-string";

Amplify.configure(getConfigData());
const query = queryString.parse(window.location.search);

const App = ({ signOut, user }) => {
  const { setCredentials, loading, statusCode } = useCredentials();

  useEffect(() => {
    if (user) {
      setCredentials();
    }
  }, [user]);

  return (
    <ErrorHandler statusCode={statusCode} loading={loading}>
      <div
        style={{
          // backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          opacity:"0.2px"
        }}
      >
        <Router />
      </div>
      <SnackbarComponent />
    </ErrorHandler>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
