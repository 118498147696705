import React from 'react'
import Form from './form/form'
import Breadcrumb from '../../breadCrumbs/navigation'
import { useSelector } from "react-redux";

function CreateVehicleModel({action,goBack,selectedData}) {
  const width = useSelector((state) => state.width.width);

  return (
    <div style={{width:(window.innerWidth-width)-30,height:window.innerHeight-80,display:"flex",justifyContent:"center"}}>
      <Form action={action} goBack={goBack} selectedData={selectedData}/>
    </div>
  )
}

export default CreateVehicleModel
