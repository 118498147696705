import React from "react";
import "./loadingPopup.css";
import validate from "../../../assets/lottie/file.json";
import uploadLottie from "../../../assets/lottie/upload.json";

import Lottie from "react-lottie";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring

const LoadingPopup = ({ message }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: message === "Uploading" ? uploadLottie : validate,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Fade-in and zoom-in animation
  const animationProps = useSpring({
    from: { opacity: 0, transform: "scale(0)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 500 }, // Adjust the duration as needed
  });


 const mainStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1},
    config: { duration: 500 }, // Adjust the duration as needed
  });
  return (
    <animated.div  className="loading-container" style={mainStyle}>
      <animated.div className="popup-box" style={animationProps}>
        <Lottie options={defaultOptions} height={130} width={130} />
        <div className="spinner"></div> {/* Spinner */}
        Please Wait {message} Your File
      </animated.div>
    </animated.div>
  );
};

export default LoadingPopup;
