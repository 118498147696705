import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import log from "loglevel";
import "bootstrap/dist/css/bootstrap.min.css";
import BasicModal from "../../modals/basicModal";
import SuccessModal from "../../modals/successModal";
import ErrorModal from "../../modals/errorModal";
import Breadcrumb from "../../breadCrumbs/navigation";

let apigClientFactory = require('aws-api-gateway-client').default;

const UpdateEcu = () => 
{

  const tenantId = JSON.parse(localStorage.getItem('TenantID'));
 

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const [formData, updateFormData] = useState({
    model_name: "",
    ecu_name: "",
    ecu_id: "",
    can_id: "",
    ecu_description: "",
  });

  const [ open, setOpen ] = useState(false);
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog
  const [children, setChildren] = useState("");
  

  const onHandleClose = () => {
    setOpen(false);
  };

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  let { ecu_id } = useParams();

  const { model_name, ecu_name, can_id, ecu_description } = formData;

  const onHandleChange = (e) => 
  {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const loadECUList = async () => 
  {
    let pathTemplate = `/ecuservice/ecumodel/${ecu_id}`
    let method = 'GET';
    let pathParams = {};
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        },
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then((resp) => {
      const Data = resp.data.record;
      if(resp.data.status === 404){
        setOpenErrorDialog(true);
      }
      if(Array.isArray(Data)) {
        updateFormData(Data);
      }
    });
    }
    catch (err) {
      log.error("Axios error",err);
      setOpen(true);
      setChildren(err.message);
    }
  };

  useEffect(() => {
    loadECUList();
  }, []);

  const AddUpdatedECUList = async () => 
  {
    let pathParams = {};
    let pathTemplate = `/ecuservice/ecumodel/${ecu_id}`;
    let method = 'PUT';
    let body = formData;
    let additionalParams = 
    {
      //If there are query parameters or headers that need to be sent with the request you can add them here
      headers: {
        "tenant-id": tenantId,
      },
    };
  try {
    await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then((resp) => 
    {
      if(resp.data.status === 200) {
        setOpenDialog(true);
      }
      else if(resp.data.status === 500){
        setOpenErrorDialog(true);
      }
      else {
        setOpen(true);
        setChildren(resp.data.msg);
      }
    });
  }
    catch (err) 
    {
      log.error("Axios error",err);
      setOpen(true);
      setChildren(err.message);
    }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    AddUpdatedECUList();
  };

  return (
    <>
    <Breadcrumb/>
      {open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {
      openDialog && (
        <SuccessModal openDialog={openDialog} onHandleClose ={onHandleCloseDialog} />
      )}
       {
      openErrorDialog && (
        <ErrorModal openDialogError={openErrorDialog} onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      <h4>Update ECU</h4>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8">
            <label>Model Name</label>
            <input
              type="string"
              className="form-control"
              id="model_name"
              value={model_name}
              disabled
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU Name</label>
            <input
              type="string"
              className="form-control"
              value={ecu_name}
              id="ecu_name"
              disabled
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU ID</label>
            <input
              type="string"
              className="form-control"
              value={ecu_id}
              id="ecu_id"
              disabled
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>CAN ID</label>
            <input
              type="string"
              className="form-control"
              value={can_id}
              id="can_id"
              disabled
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU Description</label>
            <textarea
              className="form-control"
              placeholder="Enter ECU Description"
              value={ecu_description}
              id="ecu_description"
              as="textarea"
              maxLength={70}
              rows={3}
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default UpdateEcu;
