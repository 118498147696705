import React from "react";
import Loader from "../../loader/loader";
import StatusCodeMessage from "../../stus-code-message/status-code-message";
function ErrorHandler({ statusCode, children, loading }) {

  if (statusCode !== 200 && statusCode > 299) {
    return <StatusCodeMessage statusCode={statusCode} />;
  }

  return (
    <>
      {children}
      {loading && <Loader />}
    </>
  );
}

export default ErrorHandler;
