import React from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
  FaFileDownload,
} from "../../../constants/icons";
import { secondary } from "../../../constants/color";
import { useSelector } from "react-redux";

const FormContainer = ({
  children,
  title,
  backButton,
  goBack,
  minusWidth,
  header,
  handleSubmit,
  button,
  divWidth,
  IconButton,
  iconTitle,
  handleSubmitIconButton,
  CustomComponent
}) => {
  // Fade-in animation
  const animationProps = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 500 }, // Adjust the duration as needed
  });

  return (
    <animated.div
      style={{
        ...animationProps, // Apply the fade-in animation
        width: window.innerWidth / (divWidth ? divWidth : 2) - minusWidth,
        backgroundColor: "white",
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: 10,
      }}
    >
      {header && (
        <div
          style={{
            width: window.innerWidth / (divWidth ? divWidth : 2) - minusWidth,
            height: 50,
            backgroundColor: "#19172C",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            color: "white",
            fontSize: 14,
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            paddingLeft: "2%",
            fontFamily: "Rubik",
            justifyContent:
              button || IconButton || CustomComponent ? "space-between" : "flex-start",
            paddingRight: button || IconButton || CustomComponent ? 8 : 0,
          }}
        >
          {backButton && (
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600, marginRight: 20 }}
              onClick={() => goBack(1)}
            />
          )}

          {title}
          {IconButton && (
            <div
              style={{
                width: 30,
                height: 30,
                backgroundColor: "white",
                borderRadius: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 8,
                fontWeight: 400,
                color: "black",
                fontFamily: "Rubik",
                cursor: "pointer",
                flexDirection:"column"
              }}
              onClick={handleSubmitIconButton}
            >
              <FaFileDownload
                style={{ fontSize: 12, fontWeight: 600 }}
              />
              {iconTitle}
            </div>
          )}
          {button && (
            <div
              style={{
                width: 120,
                height: 35,
                backgroundColor: secondary,
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                fontWeight: 600,
                color: "white",
                fontFamily: "Rubik",
                cursor: "pointer",
              }}
              onClick={handleSubmit}
            >
              Submit
            </div>
          )}
          {
            CustomComponent && (
              <CustomComponent/>
            )
          }
        </div>
      )}
      {children}
    </animated.div>
  );
};

export default FormContainer;
