import React, { useLayoutEffect, useRef, useState } from "react";
import {
  convertUTCDateToLocalDate,
  getLocalStorageData,
} from "../../../utilities/helper";
import ReactPaginate from "react-paginate";
import { FaBackward, FaForward } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import Paper from "@mui/material/Paper";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAxiosApiRequest from "../../../hook/useAxiosApiRequest";
import moment from "moment"
import CampaignStatus from "./campaign-status";
function TableList() {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");
  const responseData = useRef();

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [searchTerm, setSearchTerm] = useState("");
  const [tableHeader, setTableHeader] = useState([
    { id: 1, name: "Sl.No" },
    { id: 2, name: "Version" },
    { id: 3, name: "Priority" },
    { id: 4, name: "Description" },
    { id: 5, name: "Campaign Id" },
    // { id: 6, name: "S3 URL" },
    { id: 6, name: "Created At" },
    { id: 7, name: "Updated At" },
    { id: 8, name: "Vin" },
    { id: 9, name: "Thing Name" },
  ]);
  const [showCampaignStatus,setCampaignStatus] = useState(true)

  useLayoutEffect(() => {
    getCampaign();
  }, []);

  const getCampaign = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:3000/campaign/submitcampaign",
      method: "GET",
      body: {},
      // defaultTen:"c98773ac-b06f-4c2c-a57d-2dc65df4fb4f"
    };
    const response = await apiRequest(data);
    if (response?.campaign_list.length) {
      responseData.current = response?.campaign_list;
      const filteredData = filterFields(response.campaign_list, false);
      setTableData(filteredData);
      setFilteredData(filteredData);
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const {
        version,
        priority,
        description,
        campaign_id,
        s3_url,
        created_at,
        updated_at,
        vin_range,
      } = item; // Extract necessary properties
 
      if (hide) {
        return {
          id: index + 1,
          version: version,
          priority: priority,
          description: description,
          campaign_id: campaign_id,
          // s3_url: s3_url,
          Created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          Updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
          vin: vin_range[0].vin,
          thing_name: vin_range[0].thing_name,
        };
      } else {
        return {
          id: index + 1,
          version: version,
          priority: priority,
          description: description,
          campaign_id: campaign_id,
          // s3_url: s3_url,
          Created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          Updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
          vin: vin_range[0].vin,
          thing_name: vin_range[0].thing_name,
        };
      }
    });
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const closeCampaignStatus =()=>{
    setCampaignStatus(false)
  }

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
  return (
    <ErrorHandler statusCode={200} loading={false}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "1%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={(key) => console.log("click", key)}
          showMoreIcon={false}
        />

        <br></br>

        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>

        {
          !showCampaignStatus && (
            <CampaignStatus openRecordList={showCampaignStatus} onClose={closeCampaignStatus}/>
          )
        }
      </Paper>
    </ErrorHandler>
  );
}

export default TableList;
