import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import "./BrandingForm.css";
import FormContainer from "../../../components/organizer/form-container/form-container";
import { secondary } from "../../../constants/color";
import RadioInput from "../radio-input/radio-input";
import DomainInput from "../domain-input/domain-input";
import Spinner from "../../../components/melecules/spinner/spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BrandingForm = ({ width, validateAccessPolicyAndSubmit, checkAccessValidation }, ref) => {
  const [brandName, setBrandName] = useState("");
  const [brandNameError, setBrandNameError] = useState("");
  const [mailId, setMailId] = useState("");
  const [mailIdError, setMailIdError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [formError, setFormError] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [logoFileError, setLogoFileError] = useState("");
  const [domainName, setDomainName] = useState("test");
  const [loading, setLoading] = useState(false);

  const domainInputRef = useRef();

  useImperativeHandle(ref, () => ({
    load: () => {
      setLoading(!loading);
    },
    showPopUp: () => {
      toast.success("Successfully Created Tenant", {
        position: "top-right",
      });
    },
  }));

  useEffect(() => {}, []);

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const validateForm = () => {
    checkAccessValidation()
    let isValid = true;
    if (!brandName) {
      setBrandNameError("Brand Name is required");
      isValid = false;
    } else {
      setBrandNameError("");
    }
    if (!mailId) {
      setMailIdError("Mail Id is required");
      isValid = false;
    } else {
      if (!validateEmail(mailId)) {
        isValid = false;
        setMailIdError("Enter valid mail id");
      } else {
        setMailIdError("");
      }
    }
    if (!description) {
      setDescriptionError("Description is required");
      isValid = false;
    } else {
      setDescriptionError("");
    }
    if (!selectedTheme) {
      setFormError("Theme is required");
      isValid = false;
    } else {
      setFormError("");
    }
    if (!logoFile) {
      setLogoFileError("Brand Logo is required");
      isValid = false;
    } else {
      setLogoFileError("");
    }

    if (!domainName) {
      domainInputRef.current.validate();
      isValid = false;
    } else {
      domainInputRef.current.validate();
    }
    return isValid;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB in bytes
  
    if (file) {
      // Check if the file size exceeds 5MB
      if (file.size > maxSizeInBytes) {
        setLogoFileError("File size should be below 5MB");
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setLogoFile(reader.result);
      };
  
      setLogoFileError("");
    } else {
      setLogoFileError("No file selected");
    }
  };
  
  const handleThemeChange = (theme) => {
    setSelectedTheme(theme);
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      setLoading(true);
      validateAccessPolicyAndSubmit({
        brandName,
        mailId,
        description,
        selectedTheme,
        logoFile,
        domainName,
      }).finally(() => {
        setLoading(false);
      });
    } else {
      console.log("Form validation failed");
    }
  };

  const ErrorSpan = ({ errorMessage }) => {
    return <div className="branding-error-message">{errorMessage}</div>;
  };

  return (
    <FormContainer minusWidth={width} header={true} title={"Branding"}>
      <ToastContainer />
      <div className="branding-container">
        <DomainInput
          ref={domainInputRef}
          updateDomain={(domain) => setDomainName(domain)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Rubik",
            paddingLeft: "10px",
            justifyContent: "space-between",
            paddingRight: "10px",
            marginTop: "2%",
          }}
        >
          <div className="branding-input-wrapper">
            Brand Name *
            <input
              placeholder="Enter Brand Name"
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
                setBrandNameError("");
              }}
            />
            <ErrorSpan errorMessage={brandNameError} />
          </div>
          <div className="branding-input-wrapper">
            Mail Id *
            <input
              placeholder="Enter Mail Id"
              value={mailId}
              onChange={(e) => {
                setMailId(e.target.value);
                setMailIdError("");
              }}
            />
            <ErrorSpan errorMessage={mailIdError} />
          </div>
        </div>
        <div
          style={{
            padding: "10px",
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Rubik",
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          Description *
          <textarea
            placeholder="Enter Description"
            className="branding-textarea"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError("");
            }}
          />
          <ErrorSpan errorMessage={descriptionError} />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="branding-radio-wrapper">
            Theme *
            <RadioInput
              name={"theme"}
              label={"Red & Black"}
              onChange={() => {
                handleThemeChange("Red & Black");
                setFormError("");
              }}
            />
            <RadioInput
              name={"theme"}
              label={"Blue & Green"}
              onChange={() => {
                handleThemeChange("Blue & Green");
                setFormError("");
              }}
            />
            <RadioInput
              name={"theme"}
              label={"Ocean Green & Yellow"}
              onChange={() => {
                handleThemeChange("Ocean Green & Yellow");
                setFormError("");
              }}
            />
            <ErrorSpan errorMessage={formError} />
          </div>
          <div className="branding-radio-wrapper">
            Brand Logo *
            <input
              type="file"
              className="form-control"
              required
              id="tenant_logo_filename"
              accept=".jpeg,.png"
              onChange={handleFileChange}
              style={{fontSize:"14px",fontFamily:"Rubik"}}
            />
            <ErrorSpan errorMessage={logoFileError} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 10,
          }}
        >
          <div
            className="branding-submit-button"
            onClick={handleSubmit}
          >
            {loading ? <Spinner /> : "Submit"}
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default forwardRef(BrandingForm);
