import React, { useState, useEffect, useContext } from "react";
import log from "loglevel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

let apigClientFactory = require("aws-api-gateway-client").default;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const TelematicsData = () => {
  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: awsRegion,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const [vin, setVin] = useState("");
  const [vehicles, setVehicles] = useState([]);

  const handleChange = (event) => {
    setVin(event.target.value);
    console.log(event.target.value);
  };

  const tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const getOnboardVehicles = async () => {
    let pathTemplate = "/vehicleservice/vehicle";
    let method = "GET";
    let pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    let additionalParams = {
      headers: {
        "tenant-id": tenantId,
      },
    };

    let body = {
      //This is where you define the body of the request
    };
    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          const Data = resp.data.vehicle;
          if (Array.isArray(Data)) {
            setVehicles(Data);
            console.log("Tenant data...", Data);
          }
        });
    } catch (err) {
      log.error("Failed to get vehicle list", err);
    }
  };

  useEffect(() => {
    getOnboardVehicles();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Item>
            <h5>Vehicle Data</h5>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item>
            <FormControl sx={{ minWidth: 80 }} size="small">
              <label></label>
              <select
                className="form-control"
                id="vin"
                value={vin}
                required
                onChange={handleChange}
              >
                <option>Select VIN</option>
                {vehicles.map((getVin) => (
                  <option key={getVin.id}>{getVin.vin}</option>
                ))}
              </select>
            </FormControl>
          </Item>
        </Grid>
      </Grid>

      <br></br>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&let-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=10"
        }
        width="19%"
        height="190px"
        style={{ marginRight: "0.025rem" }}
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=1"
        }
        width="27%"
        height="190px"
        style={{ marginRight: "0.025rem" }}
        frameBorder="0"
      ></iframe>

      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=3"
        }
        width="27%"
        height="190px"
        style={{ marginLeft: "0.025rem", marginRight: "0.025rem" }}
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=9"
        }
        width="26.5%"
        height="190px"
        style={{ marginLeft: "0.030rem" }}
        frameBorder="0"
      ></iframe>

      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=7"
        }
        width="25%"
        height="30%"
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=5"
        }
        width="25%"
        height="30%"
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=4"
        }
        width="25%"
        height="30%"
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=2"
        }
        width="25%"
        height="30%"
        frameBorder="0"
      ></iframe>

      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=6"
        }
        width="50%"
        height="200ox"
        frameBorder="0"
      ></iframe>
      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=8"
        }
        width="50%"
        height="200px"
        frameBorder="0"
      ></iframe>

      <iframe
        src={
          "https://monitoring.embfotaconnect.info/d-solo/fa38815f-273e-4b8c-b348-2141b2389629/telematics-data?orgId=1&refresh=5s&var-VIN=" +
          vin +
          "&from=now-1h&to=now&panelId=11"
        }
        width="100%"
        height="400px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default TelematicsData;
