import { create } from "zustand";
import { statusCodeMessage } from "../../constants/constants";
const useStatusStore = create((set) => ({
  open: false,
  message: "",
  severity: "",
  setOpen: (status) => set((state) => ({ open: status })),
  setMessage: (code, m, s) => {
    const msg = code == null?`${m}`:`${statusCodeMessage[code]}  ${m}`;
    set(() => ({ message: msg }));
    set(() => ({ severity: s }));
    set(() => ({ open: true }));
  },
}));

export default useStatusStore;
