import React, { useEffect, useState } from "react";
import Form from "./form/form";
import Breadcrumb from "../../breadCrumbs/navigation";
import { useSelector } from "react-redux";
import FormContainer from "../../components/organizer/form-container/form-container";
import Lottie from "react-lottie"; 
import indicator from "../../assets/lottie/loader.json";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import { ToastContainer, toast } from "react-toastify";
let tenantId = JSON.parse(localStorage.getItem("TenantID"));

const CreateFleet = ({ selectedData, action, goBack }) => {
  const width = useSelector((state) => state.width.width);

  const [formData, setFormData] = useState({
    fleet_id: selectedData ? selectedData.fleet_id : "",
    fleet_name: selectedData ? selectedData.fleet_name : "",
    fleet_description: selectedData ? selectedData.fleet_description : "",
    tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
  });
  const [validationError, setValidationError] = useState({
    fleet_id: false,
    fleet_name: false,
    fleet_description: false,
  });

  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (error) {
      const msg = error?.response?.data?.msg;
      if (msg) {
        toast.warn(msg, {
          position: "top-right",
        });
      } else {
        toast.error("No Network Connection", {
          position: "top-right",
        });
      }
    }
  }, [error]);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setValidationError({ ...validationError, [id]: false });
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.fleet_id) {
      setValidationError((prevState) => ({ ...prevState, fleet_id: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, fleet_id: false }));
    }

    if (!formData.fleet_name) {
      setValidationError((prevState) => ({ ...prevState, fleet_name: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, fleet_name: false }));
    }

    if (!formData.fleet_description) {
      setValidationError((prevState) => ({
        ...prevState,
        fleet_description: true,
      }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        fleet_description: false,
      }));
    }

    return isValid;
  };

  const onHandleSubmit = async () => {
    if (validateForm()) {
      if (action == "create") {
        let data = {
          pathParam: {},
          endpoint: `http://localhost:8082/fleetservice/fleet`,
          method: "POST",
          body: formData,
        };

        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            fleet_name: "",
            fleet_description: "",
            fleet_id: "",
            tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Created Fleet", {
            position: "top-right",
          });
          setTimeout(() => {}, 5000);
        }
      } else {
        let data = {
          pathParam: {},
          endpoint: `http://localhost:8082/fleetservice/fleet/${selectedData?.fleet_id}`,
          method: "PUT",
          body: formData,
        };

        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            fleet_name: "",
            fleet_description: "",
            fleet_id: "",
            tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Updated Fleet", {
            position: "top-right",
          });
          setTimeout(() => {goBack(1)}, 5000);
        }
      }
    }
  };

  const ValidationMessage = ({ message, required }) => {
    return (
      <div style={{ height: 3 }}>
        {required && <p className="error-message">{message}</p>}
      </div>
    );
  };
  return (
    <div
      style={{
        width: window.innerWidth - width - 30,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormContainer
        header={true}
        minusWidth={0}
        title={action == "create" ? "Create Fleet" : "Update Fleet"}
      >
        <div className="input-row" style={{ height: 80, marginTop: 10 }}>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Fleet Id *</p>
            <input
              type="string"
              className="input"
              id="fleet_id"
              value={formData.fleet_id}
              placeholder="Enter Fleet Id"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
              message={"Model Name Is Required*"}
              required={validationError.fleet_id}
            />
          </div>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Fleet Name *</p>
            <input
              type="string"
              className="input"
              id="fleet_name"
              value={formData.fleet_name}
              placeholder="Enter Fleet Name"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
              message={"Model Name Is Required*"}
              required={validationError.fleet_name}
            />
          </div>
        </div>
        <div className="input-container" style={{ width: "100%" }}>
          <p className="input-title">Description *</p>
          <textarea
            className="input"
            placeholder="Enter Description"
            id="fleet_description"
            value={formData.fleet_description}
            as="textarea"
            maxLength={255}
            rows={3}
            required
            onChange={onHandleChange}
            style={{ height: 100 }}
          />
          <ValidationMessage
            message={"Description Is Required*"}
            required={validationError.fleet_description}
          />
        </div>
        <div
          className="col-sm-12"
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: 60,
          }}
        >
          <button
            className="btn btn-dark btn-block"
            // disabled={loading}
            style={{ height: 38, fontSize: 14,fontFamily:"Rubik" }}
            onClick={onHandleSubmit}
          >
            {loading ? (
              <Lottie options={defaultOptions} height={25} width={25} />
            ) : action == "create" ? (
              "Submit"
            ) : (
              "Update"
            )}
          </button>
        </div>
        <ToastContainer />
      </FormContainer>
    </div>
  );
};

export default CreateFleet;
