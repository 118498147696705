import React from "react";
import { height,width } from "../../constants/constants";
import Lottie from "react-lottie";
import indicator from "../../assets/lottie/server.json";
import { useSelector } from "react-redux";

const StatusCodeMessage = ({ statusCode }) => {
  const width = useSelector((state) => state.width.width);

  const message = {
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Page Not Found",
    406: "Not Acceptable",
    408: "Request Timeout",
    429: "Too Many Request",
    500: "Internal Server Error OR Network Error",
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height - 50,
        width: (window.innerWidth - width)-25 ,
        position:"absolute",
        bottom:0,
        // backgroundColor:"white",
        flexDirection:"column",
        fontFamily:"Rubik",
        fontSize:"14px",
        fontWeight:"400"
      }}
    >
      <Lottie options={defaultOptions} height={100} width={100} />
      {message[statusCode]}
    </div>
  );
};

export default StatusCodeMessage;
