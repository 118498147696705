import React, { useLayoutEffect, useState } from "react";
import {
  getLocalStorageData,
} from "../../../utilities/helper";
import ReactPaginate from "react-paginate";
import Paper from "@mui/material/Paper";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";

function TableList() {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data

  useLayoutEffect(() => {
    getVehicleModel();
  }, []);

  const getVehicleModel = async () => {
    let data = {
      pathParam: {},
      endpoint: "/user-service/user",
      method: "GET",
      additionalParams: {
        headers: {
          "tenant-id": tenant,
        },
      },
      body: {},
    };
    const response = await apiRequest(data);

    if (response?.length) {
      const filteredData = filterFields(response, false);
      setTableData(filteredData);
      setFilteredData(filteredData);
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const { user_id, user_Name, user_Email, user_Role } = item;
      return {
        id: index + 1,
        user_id: user_id,
        user_Name: user_Name,
        user_Email: user_Email,
        user_Role: user_Role,
        action: "action",
      };
    });
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const tableHeader = [
    { id: 1, name: "Sl.No" },
    { id: 2, name: "User Id" },
    { id: 3, name: "User Name" },
    { id: 4, name: "Email" },
    { id: 5, name: "Role" },
    { id: 6, name: "Action" },
  ];

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  return (
    <ErrorHandler statusCode={200} loading={loading}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "2%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={(key) => console.log("click", key)}
        />
        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </Paper>
    </ErrorHandler>
  );
}

export default TableList;
