import "bootstrap/dist/css/bootstrap.min.css";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../breadCrumbs/navigation";
import BasicModal from "../../modals/basicModal";
import ErrorModal from "../../modals/errorModal";
import SuccessModal from "../../modals/successModal";
import "../vehicleModel/createVehicleModel.css";

let apigClientFactory = require('aws-api-gateway-client').default;

const UpdateFleet = () => 
{

  const tenantId = JSON.parse(localStorage.getItem('TenantID'));
  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";
  
  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const [formData, updateFormData] = useState({
    fleet_id: "",
    fleet_name: "",
    fleet_description: "",
  });

  const [open, setOpen] = useState(false);
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog
  const [children, setChildren] = useState("");
  

  const onHandleClose =() => {
    setOpen(false);
  }

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  const { fleet_id } = useParams();

  const { fleet_name, fleet_description } = formData;

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };


  const loadFleetList = async () => 
  {
    let pathTemplate = `/fleetservice/fleet?/${fleet_id}`
    let method = 'GET';
    let pathParams = {};
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        }
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
   try {
    await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    .then((resp) => {
      const Data = resp.data?.records[0];
      updateFormData(Data);
      if(resp.data.status === 404){
        setOpenErrorDialog(true);
      }
    });
   }
   catch (err) {
    log.error("Axios error",err);
    setOpen(true);
    setChildren(err.message);
  }
  };

  useEffect(() => {
    loadFleetList();
  }, []);

  const AddUpdatedFleetList = async () => 
  {
    let pathParams = {};
    let pathTemplate = `/fleetservice/fleet/${fleet_id}`;
    let method = 'PUT';
    let body = formData;
    let additionalParams = 
    {
      //If there are query parameters or headers that need to be sent with the request you can add them here
      headers: {
        "tenant-id": tenantId,
      }
    };
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        if(resp.data.status === 200) {
          setOpenDialog(true);
        }
        else if(resp.data.status === 500){
          setOpenErrorDialog(true);
        }
        else {
          setOpen(true);
          setChildren(resp.data.msg);
        }
      });
    }
    catch (err) {
      log.error("Axios error",err);
      setOpen(true);
      setChildren(err.message);
    }
  };

  const onHandleSubmit = (e) => 
  {
    e.preventDefault();
    AddUpdatedFleetList();
  };

  return (
    <>
    <Breadcrumb/>
      {
      open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
       {
      openDialog && (
        <SuccessModal openDialog={openDialog} onHandleClose ={onHandleCloseDialog} />
      )}
       {
      openErrorDialog && (
        <ErrorModal openDialogError={openErrorDialog} onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      <h4>Update Fleet</h4>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8">
            <label>Fleet ID</label>
            <input
              type="string"
              className="form-control"
              id="fleet_id"
              value={fleet_id}
              disabled
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>Fleet Name</label>
            <input
              type="string"
              className="form-control"
              value={fleet_name}
              id="fleet_name"
              disabled
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>Description</label>
            <textarea
              className="form-control"
              placeholder="Enter Description"
              value={fleet_description}
              id="fleet_description"
              as="textarea"
              maxLength={70}
              rows={3}
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default UpdateFleet;
