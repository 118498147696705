import React, { useLayoutEffect, useRef, useState } from "react";
import SubHeader from "../../components/melecules/sub-header/sub-header";
import TableList from "./table-list/table-list";
import Button from "../../components/melecules/button/button";
import MainHeader from "../../components/melecules/main-header/main-header";
import NewOnboardVehicle from "../new-onboard/new-onboard";
import BulkUploadVehicle from "../bulk-upload-vehicle/bulk-upload-vehicle";
function OnboardVehicle({ editable }) {
  const Tabs = [
    { id: 1, title: "Onboarded Vehicle" },
    { id: 2, title: "Onboard" },
    { id: 4, title: "Bulk Upload" }
  ];
  const [activeTab, setActiveTab] = useState(1);
  const listRef = useRef();
  const [searchQuery, setSearchQuery] = useState(""); // State to hold search query
  const [selectedData,setSelectedData] = useState(null)

  const clickTab = (id,data) => {
    setActiveTab(id);
    setSelectedData(data)
  };

  const Tab = {
    1: <TableList listRef={listRef} searchQuery={searchQuery} editData={clickTab}/>,
    2: <NewOnboardVehicle action={"create"}/>,
    3: <NewOnboardVehicle action={"edit"} goBack={clickTab} selectedData={selectedData}/>,
    4: <BulkUploadVehicle/>
  };

  const headerTitle = {
    1: "Onboarded Vehicle",
    2: "Onboard Vehicle",
    3: "Update Vehicle Model",
    4: "Bulk Upload"
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value); // Update search query state
  };

  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={activeTab === 1}
        button={true}
        onClick={clickTab}
        tab={Tabs}
        onChange={onChangeSearch}
      />
      {Tab[activeTab]}
    </div>
  );
}

export default OnboardVehicle;
