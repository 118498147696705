import * as React from "react";
import { useState, useEffect, useContext } from "react";
import log from "loglevel";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import ReactPaginate from "react-paginate";
import { FaBackward, FaForward } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../vehicleModel/viewVehicleModel.css";
import BasicModal from "../../modals/basicModal";
import ConfirmationModal from "../../modals/confirmationModal";
import SuccessDeleteModal from "../../modals/successModalDelete";
import ErrorModal from "../../modals/errorModal";
import Handler from "../../components/handler/index";
import {
  pathParams,
  GET,
  additionalParams,
  body,
} from "../../constants/constants";
import { vehicle_models } from "../../constants/endpoint";

let apigClientFactory = require("aws-api-gateway-client").default;

export default function GetFleet() {
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";
  const CURRENT_USER_TYPE = CognitoRole;
  const superAdminRole = "superAdminRole";
  const adminrole = "adminrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };
  const apigClient = apigClientFactory.newClient(config);

  const [fleet, setFleet] = useState([]);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState("");
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [openErrorDialog, setOpenErrorDialog] = useState(false); //Error dialog
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
  });

  const onHandleClose = () => {
    setOpen(false);
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onHandleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const [pageNumber, setPageNumber] = useState(0);

  const fleetPerPage = 10;
  const pagesVisited = pageNumber * fleetPerPage;
  const pageCount = Math.ceil(fleet.length / fleetPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const navigate = useNavigate();

  const getfleet = async () => { 
    let pathTemplate = "/fleetservice/fleet";
    let method = "GET";
    let pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    let additionalParams = {
      headers: {
        "tenant-id": tenantId,
      },
    };

    let body = {
      //This is where you define the body of the request
    };
    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          console.log(resp);
          const Data = resp.data.records || resp.data;
          if (Array.isArray(Data)) {
            setFleet(Data);
            console.log("Tenant data...", Data);
          }
        });
    } catch (err) {
      console.log("Axios error", err);
      setOpen(true);
      setChildren(err.message);
    }
  };

  useEffect(() => {
    getfleet();
  }, []);

  const handleDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    let pathParams = {};
    let additionalParams = {
      headers: {
        "tenant-id": tenantId,
      },
    };

    let body = {
      //This is where you define the body of the request
    };
    let pathTemplate = `/fleetservice/fleet/${id}`;
    let method = "DELETE";
    try {
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((resp) => {
          if (resp.status === 200) {
            setOpenDialog(true);
          } else if (resp.data.status === 500) {
            setOpenErrorDialog(true);
          } else {
            setOpen(true);
            setChildren(resp.data.msg);
          }
          getfleet();
        });
    } catch (err) {
      log.error("Axios error", err);
      setOpen(true);
      setChildren(err.message);
    }
  };

  function convertUTCDateToLocalDate(val) {
    let dateLocal = new Date(val);
    let timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    let result = timestamp.toLocaleString();
    return result;
  }


  return (
    <Handler
      params={{ pathParams, vehicle_models, GET, additionalParams, body }}
    >
      {({ data, apiRequest }) => {
        return (
          <>
            <div>
              {open && (
                <BasicModal open={open} handleClose={onHandleClose}>
                  <p>{children}</p>
                </BasicModal>
              )}
              {openDialog && (
                <SuccessDeleteModal
                  openDialog={openDialog}
                  onHandleClose={onHandleCloseDialog}
                />
              )}
              {openErrorDialog && (
                <ErrorModal
                  openDialogError={openErrorDialog}
                  onHandleCloseErrorDialog={onHandleCloseErrorDialog}
                />
              )}
              <ConfirmationModal
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
              <div className="heading">
                <h5>Fleet List</h5>
                <input
                  style={{
                    maxWidth: "250px",
                    padding: "5px 10px",
                    border: "2px solid #080808",
                    marginLeft: "50%",
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Fleet name..."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <br />
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={{ maxHeight: 20 }}>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Sl.No
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Fleet ID
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Fleet Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Description
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Created At
                        </TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="center">
                          Updated At
                        </TableCell>
                        {((CognitoRole &&
                          CURRENT_USER_TYPE.localeCompare(
                            superAdminRole,
                            undefined,
                            {
                              sensitivity: "base",
                            }
                          ) === 0) ||
                          (CognitoRole &&
                            CURRENT_USER_TYPE.localeCompare(
                              adminrole,
                              undefined,
                              {
                                sensitivity: "base",
                              }
                            ) === 0)) && (
                          <>
                            <TableCell sx={{ fontWeight: 700 }} align="center">
                              Update
                            </TableCell>
                            <TableCell sx={{ fontWeight: 700 }} align="center">
                              Delete
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fleet &&
                        fleet
                          .slice(pagesVisited, pagesVisited + fleetPerPage)
                          .filter((fleet) => {
                            if (value === "") {
                              return fleet;
                            } else if (
                              fleet.fleet_name
                                .toLowerCase()
                                .includes(value.toLowerCase())
                            ) {
                              return fleet;
                            }
                            return null;
                          })
                          .map((fleetList, index) => {
                            return (
                              <TableRow
                                style={
                                  index % 2
                                    ? {
                                        backgroundColor:
                                          "rgba(72, 34, 180, 0.04)",
                                      }
                                    : { backgroundColor: "white" }
                                }
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={fleetList.id}
                              >
                                <TableCell
                                  key={index}
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {index + 1}
                                </TableCell>
                                <TableCell align="center">
                                  {fleetList.fleet_id}
                                </TableCell>
                                <TableCell align="center">
                                  {fleetList.fleet_name}
                                </TableCell>
                                <TableCell align="center">
                                  {fleetList.fleet_description}
                                </TableCell>
                                <TableCell align="center">
                                  {convertUTCDateToLocalDate(
                                    fleetList.created_at
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {convertUTCDateToLocalDate(
                                    fleetList.updated_at
                                  )}
                                </TableCell>
                                {((CognitoRole &&
                                  CURRENT_USER_TYPE.localeCompare(
                                    superAdminRole,
                                    undefined,
                                    {
                                      sensitivity: "base",
                                    }
                                  ) === 0) ||
                                  (CognitoRole &&
                                    CURRENT_USER_TYPE.localeCompare(
                                      adminrole,
                                      undefined,
                                      {
                                        sensitivity: "base",
                                      }
                                    ) === 0)) && (
                                  <>
                                    <TableCell align="center">
                                      <FiEdit
                                        style={{
                                          fontSize: "120%",
                                          color: "#4822B4",
                                        }}
                                        title={"Update"}
                                        onClick={() =>
                                          navigate(
                                            `/fleet/UpdateFleet/${fleetList.fleet_id}`
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      <MdDeleteOutline
                                        style={{
                                          fontSize: "140%",
                                          color: "#4822B4",
                                        }}
                                        title={"Delete"}
                                        onClick={() =>
                                          setConfirmDialog({
                                            isOpen: true,
                                            title: "Delete data",
                                            subtitle1:
                                              "Are You Sure To Delete selected Record?",
                                            onConfirm: () => {
                                              handleDelete(fleetList.fleet_id);
                                            },
                                          })
                                        }
                                      />
                                    </TableCell>
                                  </>
                                )}
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br></br>
                <div className="pagenate">
                  <h6>Available Fleet List</h6>
                  <div>
                    <ReactPaginate
                      previousLabel={<FaBackward />}
                      nextLabel={<FaForward />}
                      breakLabel={"...."}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttns"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    ></ReactPaginate>
                  </div>
                  <h6>Total Records : {fleet.length}</h6>
                </div>
              </Paper>
              <br />
              {((CognitoRole &&
                CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
                  sensitivity: "base",
                }) === 0) ||
                (CognitoRole &&
                  CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                    sensitivity: "base",
                  }) === 0)) && (
                <button
                  style={{
                    minWidth: "30%",
                    margin: "auto",
                    display: "block",
                  }}
                  type="submit"
                  className="btn btn-dark"
                  onClick={() => navigate("/fleet/AddFleet")}
                >
                  Create Fleet
                </button>
              )}
            </div>
          </>
        );
      }}
    </Handler>
  );
}
