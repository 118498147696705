import React from "react";
import "./recordList.css";
import { useSpring, animated } from "@react-spring/web";
import Close from "@mui/icons-material/Close";
import { ArrowBackIosNewOutlinedIcon, ArrowForwardIosOutlinedIcon, FaFileDownload } from "../../../constants/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";

const RecordList = ({ records, type, openRecordList, onClose }) => {
  const slideIn = useSpring({
    transform: openRecordList ? "translateY(0%)" : "translateY(100%)",
    opacity: openRecordList ? 1 : 0,
  });

  const width = useSelector((state) => state.width.width);
  const boxWidth = (window.innerWidth - width) / 5;
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <animated.div className="bottom-sheet" style={slideIn}>
      <div className="sheet-header">
        <h2 className="sheet-title">{capitalizeFirstLetter(type)} Records</h2>
        <div className="description-details">
          <div>File Name: {records[0]?.filename}</div>
        </div>
        <div className="description-details">
          <div>
            Date:{" "}
            {moment(new Date(records[0]?.created_at)).format("MMM Do YYYY")}
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
            marginRight: 10,
          }}
        >
          <FaFileDownload />
        </div>
        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <Close />
        </div>
      </div>
      <div className="sheet-content">
        <div
          className="single-container"
          style={{ backgroundColor: "#A6B8DC" }}
        >
          <div className="slBox" style={{ width: boxWidth / 3 }}>
            Sl.No
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Vin
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Model Name
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Fleet Name
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Version
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Region
          </div>
        </div>
        <div className="sheet-record-list">
          {records?.length &&
            records.map((item, index) => (
              <div
                className="single-container"
                style={{
                  backgroundColor: index % 2 == 0 ? "white" : "#f2f2f2",
                }}
                key={index}
              >
                <div
                  className="slBox"
                  style={{
                    width: boxWidth / 3,
                    fontSize: 11,
                    fontWeight: "500",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.vin}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.model_name}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item.fleet_name}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.version}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.region}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="pagination-box">
        <ReactPaginate
          previousLabel={
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          nextLabel={
            <ArrowForwardIosOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          breakLabel={"...."}
          marginPagesDisplayed={2}
          pageCount={1}
          onPageChange={"handlePageClick"}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttns"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </animated.div>
  );
};

export default RecordList;
