import React from 'react'
import { useSelector } from "react-redux";

const Container = ({children}) => {
    const width = useSelector((state) => state.width.width);

  return (
    <div style={{width:(window.innerWidth - width)-20}}>
      {children}
    </div>
  )
}

export default Container
