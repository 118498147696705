import React from 'react'

function TelemeticsScreen() {
  return (
    <div>
      TelemeticsScreen
    </div>
  )
}

export default TelemeticsScreen
