import React, { Suspense } from "react";
import { pages } from "./page-data";
import { roleRules } from "./role-rules";
import { filterPagesByRole } from "../utilities/helper";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SideBar from "../components/organizer/side-bar";
import WindowDimensions from "../hook/use-dimension";
function UserRoleManager({ userType }) {
  console.log("userType",userType)
  const screens = filterPagesByRole(pages, roleRules[userType]);
  const { windowDimensions } = WindowDimensions();
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <SideBar screens={screens.filter((x) => x.isNav == true)} />
      <Box
        component="main"
        sx={{
          p: 1,
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            overflow: "scroll",
            scrollbarWidth: "none" /* For Firefox */,
            "-ms-overflow-style": "none" /* For Internet Explorer and Edge */,
            height: windowDimensions.height - 20,
          }}
        >
          <Routes>
            {screens &&
              screens.map((item, index) => (
                <Route
                  key={item?.id}
                  path={item?.path}
                  element={<item.elements editable={item.editable} />}
                />
              ))}
          </Routes>
        </div>
      </Box>
    </div>
  );
}

export default UserRoleManager;
