import * as type from '../type';

const initialState = {
  width: 0,
}

export default function width(state = initialState, action) {
  switch (action.type) {
    case type.SET_WIDTH:
      return {
        ...state,
        width: action.payload
      }
    default:
      return state
  }
}