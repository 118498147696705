import React, { useState, useEffect, useContext } from "react";
import log from "loglevel";
import "bootstrap/dist/css/bootstrap.min.css";

import "../vehicleModel/createVehicleModel.css";
import BasicModal from "../../modals/basicModal";
import SuccessCreateModal from "../../modals/successModalCreate";
import ErrorModal from "../../modals/errorModal";
import Breadcrumb from "../../breadCrumbs/navigation";

let apigClientFactory = require('aws-api-gateway-client').default;

export default function CreateEcu() 
{
  const tenantId = JSON.parse(localStorage.getItem('TenantID'));
  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);
  
  //initializing usestate with data needed for ecu creation
  const [ formData, updateFormData ] = useState({
    model_name: "",
    ecu_name: "",
    ecu_id: "",
    can_id: "",
    ecu_description: "",
    is_primary: false,
  });

  //state for getting model name and modelID
  const [modelName, setModelName] = useState([]);
  const [modelId, setModelId] = useState('');
  //state defined for handling dialog box used for displaying responses
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState("");
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog

  //function to close dialog box
  const onHandleClose =() => {
    setOpen(false);
  }

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  const { model_name, ecu_name, ecu_id, can_id, ecu_description, is_primary } = formData;

  const ecuRequest = {
    model_id:   `${modelId}`,
    model_name: `${model_name}`,
    ecu_name: `${ecu_name}`,
    ecu_id: `${ecu_id}`,
    can_id: `${can_id}`,
    tenant_id: tenantId,
    ecu_description: `${ecu_description}`,
    is_primary: is_primary === "true" ? true : false,
  }


  const loadECU = async () => 
  {
    let pathParams = 
    {

      };
      // Template syntax follows url-template https://www.npmjs.com/package/url-template
      let pathTemplate = '/ecuservice/ecumodel'
      let method = 'POST';
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        },
      };
       
      let body = ecuRequest;
   try {
     //axios post request for submitting data for ecu creation
     await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
     .then((resp) => {
      if(resp.status === 200) {
        setOpenDialog(true);
      }
      else if(resp.data.status === 500){
        setOpenErrorDialog(true);
      }
      else {
        setOpen(true);
        setChildren(resp.data.msg);
      }
     });
   }
   catch (err) {
    log.error("Axios error",err);
    setOpen(true);
    setChildren(err.response.data.msg || err.response.data.message);
  }
  };

  const GetVehicleModels = async () => 
  {
    let pathTemplate = '/vehicleservice/vehiclemodel'
    let method = 'GET';
    let pathParams = 
    {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenantId,
        },
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
    //axios get request for displaying model names available
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        const Data = resp.data.vehicle_models;
              if(Array.isArray(Data)) {
                setModelName(Data);
              }
      });
    }
      catch (err) {
        log.error("Axios error",err);
        setOpen(true);
        setChildren(err.message);
      }
  };

  useEffect(() => 
  {
    GetVehicleModels();
  }, []);

  const onHandleChange = (e) =>
  {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleModelChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    const selectedModel = modelName.find(model => model.model_name === e.target.value);
    setModelId(selectedModel.model_id);
  };

  const onHandleSubmit = (e) => 
  {
    e.preventDefault();
    loadECU();
    updateFormData({
      ecu_description: ''
    });
    e.target.reset();
  };

  return (
    <div>
      <Breadcrumb/>
      {
        //calling modal component
      open && 
      (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {
      openDialog && (
        <SuccessCreateModal openDialog={openDialog} onHandleClose ={onHandleCloseDialog} />
      )}
       {
      openErrorDialog && (
        <ErrorModal openDialogError={openErrorDialog} onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      <h4>Create ECU</h4>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8">
            <label>Model Name</label>
            <select
              className="form-control"
              id="model_name"
              value={model_name}
              required
              onChange={handleModelChange}
            >
              <option value={""}>Select Model Name</option>
              {modelName && modelName.map((getModelName) => (
                <option key={getModelName.id}>{getModelName.model_name}</option>
              ))}
              
            </select>
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU Name</label>
            <input
              type="string"
              className="form-control"
              value={ecu_name}
              id="ecu_name"
              placeholder="Enter ECU Name"
              maxLength={50}
              required
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU ID</label>
            <input
              type="string"
              className="form-control"
              value={ecu_id}
              accept="characters"
              id="ecu_id"
              placeholder="Enter ECU ID"
              maxLength={50}
              required
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>CAN ID</label>
            <input
              type="string"
              className="form-control"
              value={can_id}
              id="can_id"
              placeholder="Enter CAN ID"
              maxLength={50}
              required
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>ECU Description</label>
            <textarea
              className="form-control"
              placeholder="Enter ECU Description"
              value={ecu_description}
              id="ecu_description"
              as="textarea"
              maxLength={70}
              rows={3}
              required
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="form-group col-md-6">
            <label className="col-form-label">Select ECU Type</label>
            <div className="col-sm-8">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="is_primary"
                  value={true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Primary
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions2"
                  id="is_primary"
                  value={true}
                  onChange={onHandleChange}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Secondary
                </label>
              </div>
            </div>
          </div>
          <br/>
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
