import "bootstrap/dist/css/bootstrap.min.css";
import log from 'loglevel';
import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../../breadCrumbs/navigation";
import BasicModal from "../../modals/basicModal";
import ErrorModal from "../../modals/errorModal";
import SuccessCreateModal from "../../modals/successModalCreate";
import "./createVehicleModel.css";

let apigClientFactory = require('aws-api-gateway-client').default;

export default function AddvehicleModel() 
{

  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";
  const CURRENT_USER_TYPE = CognitoRole;
  const superAdminRole = 'superAdminRole';

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);
 
  const tenant = JSON.parse(localStorage.getItem('TenantID'));

  //initializing state with required fields for vehicle model creation
  const [formData, updateFormData] = useState({
    model_name: "",
    description: "",
    year_of_introduction: "",
    engine_type: "",
    tenant_id: tenant
    // tenant_name: ""
  });

  const [open, setOpen] = useState(false);      //state to handle dialog box to display responses
  const [children, setChildren] = useState(""); //state to handle responses 
  const [ tenantName, setTenantName ] = useState([]);
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog

  const onHandleClose =() => 
  {
    setOpen(false);   //closes the dialog box
  }

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  const { model_name, description, year_of_introduction, engine_type, tenant_name } =
    formData || {};

  useEffect(() => 
  {
    GetTenantName();
  }, []);

  const GetTenantName = async () => 
  {
    let pathParams = 
    {

      };
      let pathTemplate = '/tenantservice/tenant'
      let method = 'GET';
      let additionalParams = 
      {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": tenant
        },
      };
       
      let body = {};
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((resp) => {
        console.log("Tenant Data.....",resp)
        const Data = resp.data;
        if(Array.isArray(Data)) {
          setTenantName(Data);
          console.log("Tenant data...",Data);
        }
      });
    }
      catch (err) {
        log.error("Axios error",err);
        setOpen(true);
        setChildren(err.message);
      }
  };

  //sending post request for creation of vehicle model
  const loadVehicleModel = async () => 
  {
    let pathParams = 
    {

      };
      // Template syntax follows url-template https://www.npmjs.com/package/url-template
      let pathTemplate = '/vehicleservice/vehiclemodel'
      let method = 'POST';
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenant
        }
      };
       
      //let additionalParams = {};
      let body = formData;
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      // .post(`${url}`, formData)
      .then((resp) => {
        if(resp.status === 200) {
          setOpenDialog(true);
        }
        else if(resp.data.status === 500){
          setOpenErrorDialog(true);
        }
        else {
          setOpen(true);
          setChildren(resp.data.msg || resp.data);
        }
      }).catch( function(error){
        console.log("FAILURE RESPONSE",error);
      });
    }
    catch (err) {
      log.error(" error");
      // log.error("Axios error",err.message);
      // setOpen(true);
      // setChildren(err.response.data.msg || err.response.data.message);
    }
  };

  const onHandleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const onHandleTenantName = (e) => {
    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
   
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    loadVehicleModel();
    updateFormData({
      model_name: "",
      description: "",
      year_of_introduction: "",
      engine_type: "",
      tenant_id: tenant
    });
  };

  return (
    <div>
      <Breadcrumb/>
      {/* dialog component */}
      {
      open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {
      openDialog && (
        <SuccessCreateModal 
        openDialog={openDialog} 
        onHandleClose ={onHandleCloseDialog} />
      )}
       {
      openErrorDialog && (
        <ErrorModal 
        openDialogError={openErrorDialog} 
        onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      <h4>Add New Vehicle Model</h4>
      <br></br>
      <div className="createvehicle">
        {/* form for getting user inputs */}
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8">
            <label>Model Name</label>
            <input
              type="string"
              className="form-control"
              id="model_name"
              value={model_name}
              placeholder="Enter Model Name"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Engine Type</label>
            <input
              type="string"
              className="form-control"
              value={engine_type}
              id="engine_type"
              placeholder="Enter Engine type"
              maxLength={255}
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="col-sm-8">
            <label>Year of introduction</label>
            <input
              type="date"
              className="form-control"
              value={year_of_introduction}
              id="year_of_introduction"
              placeholder="Enter date of introduction"
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          {CognitoRole && CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
            sensitivity: "base",
          }) === 0 && (
          <div className="col-sm-8">
          <label>Select Tenant</label>
          <select
            className="form-control"
            id="tenant_name"
            value={tenant_name}
            required
            onChange={onHandleTenantName}
            > 
            <option>Select Tenant Name</option>
            {tenantName && tenantName.map((getTenantName) => (
              <option key={getTenantName.id}>{getTenantName.tenantName}</option>
            ))}
          </select>
        </div>
      )}
      <br />
          <div className="col-sm-8">
            <label>Description</label>
            <textarea
              className="form-control"
              placeholder="Enter Description"
              value={description}
              id="description"
              as="textarea"
              maxLength={255}
              rows={3}
              required
              onChange={onHandleChange}
            />
          </div>
          <br></br>
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}