import * as type from '../type';

const initialState = {
  tokens: null,
}

export default function tokens(state = initialState, action) {
  switch (action.type) {
    case type.SET_TOKENS:
      return {
        ...state,
        tokens: action.payload
      }
    default:
      return state
  }
}