import React, { useEffect, useState } from "react";
import "./form.css";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/loader/loader";
import Lottie from "react-lottie";
import indicator from "../../../assets/lottie/loader.json";
import FormContainer from "../../../components/organizer/form-container/form-container";
function Form({ action, goBack, selectedData }) {
  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const title =
    action == "create" ? "Create New Vehicle Model" : "Update Vehicle Model";

  const [formData, setFormData] = useState({
    model_name: selectedData ? selectedData.model_name : "",
    description: selectedData ? selectedData.description : "",
    year_of_introduction: selectedData ? selectedData.year_of_introduction : "",
    engine_type: selectedData ? selectedData.engine_type : "",
    tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
  });

  const [validationError, setValidationError] = useState({
    model_name: false,
    description: false,
    year_of_introduction: false,
    engine_type: false,
    tenant_id: false,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (error) {
      console.log("error", error?.response?.data?.msg);
      const msg = error?.response?.data?.msg;
      if (msg) {
        toast.warn(msg, {
          position: "top-right",
        });
      } else {
        toast.error("No Network Connection", {
          position: "top-right",
        });
      }
    }
  }, [error]);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const onHandleSubmit = async (e) => {
    if (validateForm()) {
      if (action == "create") {
        let data = {
          pathParam: {},
          endpoint: "http://localhost:8080/vehicleservice/vehiclemodel",
          method: "POST",
          body: formData,
        };
        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            model_name: "",
            description: "",
            year_of_introduction: "",
            engine_type: "",
            tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Created Vehicle Model", {
            position: "top-right",
          });
        }
      } else {
        let data = {
          pathParam: {},
          endpoint: `http://localhost:8080/vehicleservice/vehiclemodel/${selectedData?.model_id}`,
          method: "PUT",
         
          body: formData,
        };

        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            model_name: "",
            description: "",
            year_of_introduction: "",
            engine_type: "",
            tenant_id: tenantId||process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Updated Vehicle Model", {
            position: "top-right",
          });
          setTimeout(() => {
            goBack(1);
          }, 5000);
        }
      }
    }
    // Example: apiRequest(formData);
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.model_name) {
      setValidationError((prevState) => ({ ...prevState, model_name: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, model_name: false }));
    }

    if (!formData.engine_type) {
      setValidationError((prevState) => ({ ...prevState, engine_type: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, engine_type: false }));
    }

    if (!formData.year_of_introduction) {
      setValidationError((prevState) => ({
        ...prevState,
        year_of_introduction: true,
      }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        year_of_introduction: false,
      }));
    }

    if (!formData.description) {
      setValidationError((prevState) => ({ ...prevState, description: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, description: false }));
    }

    return isValid;
  };

  const ValidationMessage = ({ message, required }) => {
    return (
      <div style={{ height: 3 }}>
        {required && <p className="error-message">{message}</p>}
      </div>
    );
  };

  return (
    <FormContainer
      title={title}
      backButton={action == "create" ? false : true}
      goBack={goBack}
      header={true}
      minusWidth={0}
    >
      <div className="input-row" style={{ height: 80, marginTop: 10 }}>
        <div className="input-container" style={{ width: "100%" }}>
          <p className="input-title">Model Name *</p>
          <input
            type="string"
            className="input"
            id="model_name"
            value={formData.model_name}
            placeholder="Enter Model Name"
            maxLength={255}
            required
            onChange={onHandleChange}
          />
          <ValidationMessage
            message={"Model Name Is Required*"}
            required={validationError.model_name}
          />
        </div>
      </div>
      <div className="input-row" style={{ height: 110 }}>
        <div className="input-container">
          <p className="input-title">Engine Type *</p>
          <input
            type="string"
            className="input"
            id="engine_type"
            value={formData.engine_type}
            placeholder="Enter Engine type"
            maxLength={255}
            required
            onChange={onHandleChange}
          />
          <ValidationMessage
            message={"Engine Type Is Required*"}
            required={validationError.engine_type}
          />
        </div>
        <div className="input-container">
          <p className="input-title">Year of introduction *</p>
          <input
            type="date"
            className="input"
            id="year_of_introduction"
            placeholder="Enter date of introduction"
            required
            value={formData.year_of_introduction}
            onChange={onHandleChange}
          />
          <ValidationMessage
            message={"Year of introduction Is Required*"}
            required={validationError.year_of_introduction}
          />
        </div>
      </div>
      <div className="input-row" style={{ height: 150 }}>
        <div className="input-container" style={{ width: "100%" }}>
          <p className="input-title">Description *</p>
          <textarea
            className="input"
            placeholder="Enter Description"
            id="description"
            value={formData.description}
            as="textarea"
            maxLength={255}
            rows={3}
            required
            onChange={onHandleChange}
            style={{ height: 100 }}
          />
          <ValidationMessage
            message={"Description Is Required*"}
            required={validationError.description}
          />
        </div>
      </div>
      <div
        className="form-group row"
        style={{ position: "absolute", bottom: 30 }}
      >
        <div className="col-sm-12">
          <button
            className="btn btn-dark btn-block"
            disabled={loading}
            style={{ height: 38, fontSize: 14,fontFamily:"Rubik" }}
            onClick={onHandleSubmit}
          >
            {loading ? (
              <Lottie options={defaultOptions} height={25} width={25} />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
      <ToastContainer />
    </FormContainer>
  );
}

export default Form;
