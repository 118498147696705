import {
  HomeRoundedIcon,
  DirectionsCarIcon,
  NoteAddIcon,
  MemoryRoundedIcon,
  CampaignRoundedIcon,
  RestartAltOutlinedIcon,
  Person2SharpIcon,
  CommuteIcon,
  MiscellaneousServicesIcon,
  CloudCircleIcon,
  AssessmentIcon,
  HubIcon,
} from "../constants/icons";

import {
  Dashboard,
  OnboardVehicle,
  CampaignScreen,
  MonitorScreen,
  TelemeticsScreen,
  VehicleModel,
  CreateVehicleModel,
  FleetScreen,
  CreateFleet,
  EcuScreen,
  NewOnboard,
  CreateCampaign,
  ViewUser,
  AddUser,
  OnboardTenet,
  CreateTenet,
} from "../constants/pages";

const pages = [
  {
    id: 1,
    path: "/",
    elements: Dashboard,
    name: "Dashboard",
    icon: <HomeRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 2,
    path: "/dashboard",
    elements: Dashboard,
    name: "Services",
    icon: <MiscellaneousServicesIcon style={{ fontSize: 18 }} />,
  },

  {
    id: 3,
    path: "/VehicleModel",
    elements: VehicleModel,
    name: "Vehicle Model",
    icon: <DirectionsCarIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 8,
    path: "/FleetScreen",
    elements: FleetScreen,
    name: "Fleet",
    icon: <CommuteIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 4,
    path: "/onboardVehicle",
    elements: OnboardVehicle,
    name: "Onboard Vehicle",
    icon: <DirectionsCarIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 5,
    path: "/telemeticsScreen",
    elements: TelemeticsScreen,
    name: "Telematics",
    icon: <CloudCircleIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 6,
    path: "/monitorScreen",
    elements: MonitorScreen,
    name: "Monitoring",
    icon: <AssessmentIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 7,
    path: "/CreateVehicleModel",
    elements: CreateVehicleModel,
    name: "Create Vehicle Model",
    icon: <DirectionsCarIcon style={{ fontSize: 18 }} />,
  },
 
  {
    id: 9,
    path: "/CreateFleet",
    elements: CreateFleet,
    name: "Fleet",
    icon: <CommuteIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 10,
    path: "/EcuScreen",
    elements: EcuScreen,
    name: "ECU",
    icon: <MemoryRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 11,
    path: "/NewOnboard",
    elements: NewOnboard,
    name: "ECU",
    icon: <MemoryRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 12,
    path: "/CampaignScreen",
    elements: CampaignScreen,
    name: "Campaign",
    icon: <CampaignRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 13,
    path: "/CreateCampaign",
    elements: CreateCampaign,
    name: "Campaign",
    icon: <CampaignRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 14,
    path: "/ViewUser",
    elements: ViewUser,
    name: "User",
    icon: <Person2SharpIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 15,
    path: "/ViewUser",
    elements: ViewUser,
    name: "User",
    icon: <Person2SharpIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 16,
    path: "/AddUser",
    elements: AddUser,
    name: "User",
    icon: <Person2SharpIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 17,
    path: "/ViewUser",
    elements: ViewUser,
    name: "Role",
    icon: <MiscellaneousServicesIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 18,
    path: "/OnboardTenet",
    elements: OnboardTenet,
    name: "Tenet",
    icon: <MemoryRoundedIcon style={{ fontSize: 18 }} />,
  },
  {
    id: 19,
    path: "/CreateTenet",
    elements: CreateTenet,
    name: "Tenet",
    icon: <MemoryRoundedIcon style={{ fontSize: 18 }} />,
  },
];

export { pages };
