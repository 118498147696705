import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Grid,
  ListItem,
  ListItemIcon,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import MainHeader from "../../components/melecules/main-header/main-header";
function Dashboard({ editable }) {
  let tenantId = 1;
  const cardStyles = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
  };
  const cardStyles2 = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
    paddingBottom: "150px",
  };
  const cardStyles3 = {
    border: "1px solid #a4a5a5a8",
    borderRadius: "5%",
    paddingBottom: "50px",
  };
  return (
    <div>
      <MainHeader title={"Dashboard"}/>
      <Grid container spacing={2}>
        {true ? (
          <Grid item xs={3}>
            <Card sx={cardStyles}>
              <ListItem disableGutters style={{ paddingLeft: "5px" }}>
                <ListItemIcon>
                  <FiberManualRecordIcon
                    style={{ fontSize: "small", color: "#e69704f1" }}
                  />
                </ListItemIcon>
                <Typography style={{ marginLeft: "-40px" }}>
                  Onboarded Tenants
                </Typography>
              </ListItem>
              <Typography
                variant="h5"
                color="#e69704f1"
                style={{ marginLeft: "0px", marginRight: "0px" }}
              >
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204330346&to=1690225930346&panelId=1"
                  width="100%"
                  height="100%"
                  
                ></iframe>{" "}
              </Typography>
            </Card>
          </Grid>
        ) : null}
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>Fleets</Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {true ? (
                // superAdmin URL
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204363050&to=1690225963050&panelId=2"
                  width="100%"
                  height="100%"
                  
                ></iframe>
              ) : (
                // Admin URL
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=2"
                  }
                  width="100%"
                  height="100%"
                  
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>Models</Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {true ? (
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204392789&to=1690225992789&panelId=3"
                  width="100%"
                  height="100%"
                  
                ></iframe>
              ) : (
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=3"
                  }
                  width="100%"
                  height="100%"
                  
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card sx={cardStyles}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>
                Onboarded Vehicles
              </Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "0px", marginRight: "0px" }}
            >
              {true ? (
                <iframe
                  src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204414449&to=1690226014450&panelId=4"
                  width="100%"
                  height="100%"
                  
                ></iframe>
              ) : (
                <iframe
                  src={
                    "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                    tenantId +
                    "&panelId=4"
                  }
                  width="100%"
                  height="100%"
                  
                ></iframe>
              )}
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "5%" }}>
        <TableContainer sx={{ maxHeight: 440, border: "1px solid #a4a5a5a8" }}>
          <ListItem disableGutters style={{ paddingLeft: "10px" }}>
            <ListItemIcon>
              <FiberManualRecordIcon
                style={{ fontSize: "small", color: "#e69704f1" }}
              />
            </ListItemIcon>
            <Typography
              style={{
                marginLeft: "-30px",
              }}
            >
              Onboarded Vehicles
            </Typography>
          </ListItem>
          {true ? (
            <iframe
              src="https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6as2/frontend-stats?orgId=1&from=1690204443278&to=1690226043278&panelId=5"
              width="100%"
              height="100%"
              
            ></iframe>
          ) : (
            <iframe
              src={
                "https://monitoring.embfotaconnect.info/d-solo/f8fdca96-5686-403e-8439-6009faae6fdf/frontend-stats-admin?orgId=1&from=1690125712298&to=1690298512298&var-tenant_id=" +
                tenantId +
                "&panelId=5"
              }
              width="100%"
              height="100%"
              
            ></iframe>
          )}
        </TableContainer>
      </Paper> 

      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={6}>
          <Card sx={cardStyles2}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>
                Active Users
              </Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "20px" }}
            >
              06
            </Typography>
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Last Activity:21/09/2022
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={cardStyles3}>
            <ListItem disableGutters style={{ paddingLeft: "5px" }}>
              <ListItemIcon>
                <FiberManualRecordIcon
                  style={{ fontSize: "small", color: "#e69704f1" }}
                />
              </ListItemIcon>
              <Typography style={{ marginLeft: "-40px" }}>Campaign</Typography>
            </ListItem>
            <Typography
              variant="h5"
              color="#e69704f1"
              style={{ marginLeft: "20px" }}
            >
              12
            </Typography>
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Last Campaign:21/01/2023
            </Typography>
            <br />
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Active vehicles:100
            </Typography>
            <br />
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Vehicles with Updated Firmware:250
            </Typography>
            <br />
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Vehicles Pending for Firmware Updation:50
            </Typography>
            <br />
            <Typography variant="caption" style={{ marginLeft: "20px" }}>
              Active campaigns:05
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
