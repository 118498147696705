import React, { useState } from "react";
import "./MainHeader.css"; // Import CSS file for styles
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useSelector } from "react-redux";
import { Add } from "../../../constants/icons";

const MainHeader = ({ title, searchBar, button, onClick, tab,onChange }) => {
  const width = useSelector((state) => state.width.width);

  const [selected, setSelected] = useState(1);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const selectTap = (id) => {
    setSelected(id);
    onClick(id)
  };
  return (
    <div className="main-header" style={{width:(window.innerWidth-width)-30}}>
      <p className="header-title" style={{width:window.innerWidth/4}}>{title}</p>

      <div className="tab">
        {tab &&
          tab.map((item) => (
            <div
              className="button"
              onClick={() => selectTap(item?.id)}
              key={item?.id}
              style={{
                backgroundColor: selected == item?.id ? "#19172C" : "#d3d3d3",
                width:window.innerWidth/9,
                alignItems:"center",
                justifyContent:"center"
              }}
            >
              <p
                className="button-text"
                style={{ color: selected == item?.id ? "#fff" : "#000" }}
              >
                {item.title}
              </p>
              {/* <Add style={{ color: "white",fontSize:14 }} /> */}
            </div>
          ))}
      </div>
      {searchBar ? (
        <input type="text" placeholder="Search..." className="search-box" onChange={onChange}/>
      ):<div style={{width:"300px"}}/>}
      {/* {button && (
        <div className="button" onClick={onClick}>
          <p className="button-text">Create</p>
          <Add style={{ color: "white",fontSize:14 }} />
        </div>
      )} */}

      <div className="user-profile">
        <img
          src="https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"
          alt="User Profile"
          style={{ height: 30, width: 30 }}
          onClick={() => signOut()}
        />
      </div>
    </div>
  );
};

export default MainHeader;
