import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./AccessPolicyForm.css";
import FormContainer from "../../../components/organizer/form-container/form-container";
import RadioInput from "../radio-input/radio-input";
import CheckBox from "../check-box/check-box";

const AccessPolicyForm = ({ width }, ref) => {
  const [passwordLength, setPasswordLength] = useState("");
  const [passwordLengthError, setPasswordLengthError] = useState("");
  const [passwordExpiration, setPasswordExpiration] = useState("");
  const [passwordExpirationError, setPasswordExpirationError] = useState("");
  const [multiFactorAuth, setMultiFactorAuth] = useState(true);
  const [rememberUserDevice, setRememberUserDevice] = useState(true);
  const [multiFactorAuthError, setMultiFactorAuthError] = useState("");
  const [rememberUserDeviceError, setRememberUserDeviceError] = useState("");
  const [accessToWebClient, setAccessToWebClient] = useState(true);
  const [accessToWebClientError, setAccessToWebClientError] = useState("");
  const [passCharacterNum, setPassCharacterNum] = useState(false);
  const [passCharacterSp, setPassCharacterSpe] = useState(false);
  const [passCharacterUp, setPassCharacterUp] = useState(false);
  const [passCharacterLo, setPassCharacterLo] = useState(false);
  const [passCharacterError, setPassCharacterError] = useState("");

  useImperativeHandle(ref, () => ({
    validate: () => handleSubmit(),
  }));
  console.log("multiFactorAuth",multiFactorAuth)
  const validateForm = () => {
    let isValid = true;

    if (!passwordLength) {
      setPasswordLengthError("Password Length is required");
      isValid = false;
    } else if (isNaN(passwordLength)) {
      setPasswordLengthError("Password Length must be a number");
      isValid = false;
    } else if (passwordLength < 6 || passwordLength > 99) {
      setPasswordLengthError(
        "Password Length should be between 6 and 99 characters"
      );
      isValid = false;
    } else {
      setPasswordLengthError("");
    }

    if (!passwordExpiration) {
      setPasswordExpirationError("Password Expiration is required");
      isValid = false;
    } else if (isNaN(passwordExpiration)) {
      setPasswordExpirationError("Password Expiration must be a number");
      isValid = false;
    } else {
      setPasswordExpirationError("");
    }

    // if (!multiFactorAuth) {
    //   setMultiFactorAuthError("Multi Factor Authentication is required");
    //   isValid = false;
    // } else {
    //   setMultiFactorAuthError("");
    // }

    // if (!rememberUserDevice) {
    //   setRememberUserDeviceError("Remember User Device is required");
    //   isValid = false;
    // } else {
    //   setRememberUserDeviceError("");
    // }

    // if (!accessToWebClient) {
    //   setAccessToWebClientError("Access To Web Client is required");
    //   isValid = false;
    // } else {
    //   setAccessToWebClientError("");
    // }

    if (
      !passCharacterSp &&
      !passCharacterNum &&
      !passCharacterLo &&
      !passCharacterUp
    ) {
      setPassCharacterError("Select Password Strength");
      isValid = false;
    } else {
      setPassCharacterError("");
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      return {
        passwordLength,
        passwordExpiration,
        multiFactorAuth,
        rememberUserDevice,
        accessToWebClient,
        passCharacterNum,
        passCharacterSp,
        passCharacterUp,
        passCharacterLo,
      };
    } else {
      console.log("Form validation failed");
    }
  };

  const handlePasswordLengthChange = (e) => {
    const value = e.target.value;

    // Check if the value is a number
    if (!/^\d+$/.test(value)) {
      setPasswordLengthError("Password length must be a number");
      setPasswordLength(value);
      return;
    }

    // Check if the number is within the allowed range
    const numberValue = parseInt(value, 10);
    if (numberValue < 6 || numberValue > 99) {
      setPasswordLengthError("Password length must be between 6 and 99");
    } else {
      setPasswordLengthError("");
    }

    setPasswordLength(value);
  };

  const handlePasswordExpirationChange = (e) => {
    const value = e.target.value;

    // Check if the value is a number
    if (!/^\d+$/.test(value)) {
      setPasswordExpirationError("Password expiration must be a number");
      setPasswordExpiration(value);
      return;
    }

    // Check if the number is within the allowed range
    const numberValue = parseInt(value, 10);
    if (numberValue < 1 || numberValue > 5) {
      setPasswordExpirationError("Password expiration must be between 1 and 5 days");
    } else {
      setPasswordExpirationError("");
    }

    setPasswordExpiration(value);
  };


  const ErrorSpan = ({ errorMessage }) => {
    return <div className="error-message">{errorMessage}</div>;
  };

  return (
    <FormContainer minusWidth={width} header={true} title={"Access Policy"}>
      <div className="form-container">
        {/* <div className="form-row" style={{paddingTop:20}}>
          <div className="form-column">
            Password Length(Characters) *
            <input
              className="input-field"
              placeholder="Enter Password Length"
              value={passwordLength}
              onChange={(e) => {
                setPasswordLength(e.target.value);
                setPasswordLengthError("");
              }}
            />
            <ErrorSpan errorMessage={passwordLengthError} />
          </div>
          <div className="form-column">
            Password Expiration(Days) *
            <input
              className="input-field"
              placeholder="Enter Temporary Password Expiration"
              value={passwordExpiration}
              onChange={(e) => {
                setPasswordExpiration(e.target.value);
                setPasswordExpirationError("");
              }}
            />
            <ErrorSpan errorMessage={passwordExpirationError} />
          </div>
        </div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Rubik",
            paddingLeft: "10px",
            justifyContent: "space-between",
            paddingRight: "10px",
            marginTop: "2%",
          }}
        >
          <div className="branding-input-wrapper">
            Password Length(Characters) *
            <input
              placeholder="Enter Password Length"
              value={passwordLength}
              onChange={handlePasswordLengthChange}
            />
            <ErrorSpan errorMessage={passwordLengthError} />
          </div>
          <div className="branding-input-wrapper">
            Password Expiration(Days) *
            
            <input
              placeholder="Enter Temporary Password Expiration"
              value={passwordExpiration}
              onChange={handlePasswordExpirationChange}
            />

            <ErrorSpan errorMessage={passwordExpirationError} />
          </div>
        </div>
        <div className="form-section" style={{ paddingTop: 15 }}>
          Password length should be minimum of 6 characters to maximum of 99
          characters *
          <CheckBox
            name={"pass-number"}
            label={"Numbers"}
            onChange={() => {
              setPassCharacterNum(!passCharacterNum);
              setPassCharacterError("");
            }}
            error={null}
            value={passCharacterNum}
          />
          <CheckBox
            name={"pass-char"}
            label={"Special Characters"}
            onChange={() => {
              setPassCharacterSpe(!passCharacterSp);
              setPassCharacterError("");
            }}
            error={null}
            value={passCharacterSp}
          />
          <CheckBox
            name={"pass-upper"}
            label={"Uppercase letters"}
            onChange={() => {
              setPassCharacterUp(!passCharacterUp);
              setPassCharacterError("");
            }}
            error={null}
            value={passCharacterUp}
          />
          <CheckBox
            name={"pass-lower"}
            label={"Lowercase letters"}
            onChange={() => {
              setPassCharacterLo(!passCharacterLo);
              setPassCharacterError("");
            }}
            error={null}
            value={passCharacterLo}
          />
          <p className="checkbox-container">
            Password should contain at least one of the above given options
          </p>
          <ErrorSpan errorMessage={passCharacterError} />
        </div>
        <div className="form-row">
          <div style={{ width: "50%" }}>
            Multi Factor Authentication *
            <div className="radio-row">
              <RadioInput
                name={"multi"}
                label={"Optional"}
                onChange={(e) => {
                  setMultiFactorAuth(false);
                  setMultiFactorAuthError("");
                }}
                error={multiFactorAuthError}
              />
              <RadioInput
                name={"multi"}
                label={"Required"}
                onChange={(e) => {
                  setMultiFactorAuth(true);
                  setMultiFactorAuthError("");
                }}
                checked={multiFactorAuth}
                error={multiFactorAuthError}
              />
            </div>
            <ErrorSpan errorMessage={multiFactorAuthError} />
          </div>
          <div style={{ width: "50%" }}>
            Remember User Device *
            <div className="radio-row">
              <RadioInput
                name={"user"}
                label={"Optional"}
                onChange={(e) => {
                  setRememberUserDevice(true);
                  setRememberUserDeviceError("");
                }}
                checked={rememberUserDevice}
                error={rememberUserDeviceError}
              />
              <RadioInput
                name={"user"}
                label={"Not Required"}
                onChange={(e) => {
                  setRememberUserDevice(false);
                  setRememberUserDeviceError("");
                }}
                error={rememberUserDeviceError}
              />
            </div>
            <ErrorSpan errorMessage={rememberUserDeviceError} />
          </div>
        </div>
        <div className="access-section">
          Access To Web Client *
          <div className="radio-row">
            <RadioInput
              name={"access"}
              label={"Optional"}
              onChange={(e) => {
                setAccessToWebClient(true);
                setAccessToWebClientError("");
              }}
              checked={accessToWebClient}
              error={accessToWebClientError}
            />
            <RadioInput
              name={"access"}
              label={"Not Required"}
              onChange={(e) => {
                setAccessToWebClient(false);
                setAccessToWebClientError("");
              }}
              error={accessToWebClientError}
            />
          </div>
          <ErrorSpan errorMessage={accessToWebClientError} />
        </div>
        <div style={{ height: 20 }} />
      </div>
    </FormContainer>
  );
};

export default forwardRef(AccessPolicyForm);
