import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./create-tenet.css";
import BrandingForm from "./branding-form/branding-form";
import AccessPolicyForm from "./access-policy-form/access-policy-form";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviewModel from "./preview-model/preview-model";

const CreateTenet = () => {
  const width = useSelector((state) => state.width.width);
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();

  const [show, setShow] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const accessRef = useRef();
  const brandRef = useRef();

  useEffect(() => {
    // generateTenant();
  }, []);

  const checkAccessValidation = () => {
    const accessData = accessRef.current.validate();
  };

  const validateAccessPolicyAndSubmit = async (brandData) => {
    const accessData = accessRef.current.validate();
    
    if (accessData) {
      setPreviewData({ ...accessData, ...brandData });
      setOpenDialog(true);
    }
  };

  const generateTenant = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenant/tenantid`,
      method: "GET",
      headers: {},
      body: {},
    };
    const response = await apiRequest(data);
    if (response) {
      return response;
    } else {
      // toast.error("Something wrong", {
      //   position: "top-right",
      // });
    }
  };

  const handleSubmit = async () => {
    const tenant_id = await generateTenant();
    const tenantStagingRequest = {
      tenant_name: `${previewData?.brandName}`,
      tenant_description: `${previewData?.description}`,
      tenant_domain: `${previewData?.domainName}`,
      tenant_owner_email_id: `${previewData?.mailId}`,
      tenant_logo_filename: previewData?.logoFile,
      tenant_theme: `${previewData?.selectedTheme}`,
      callback_url: process.env.REACT_APP_REDIRECT_URL,
      logout_url: process.env.REACT_APP_REDIRECT_URL,
      tenant_password_length: parseInt(previewData?.passwordLength),
      tenant_password_require_number: previewData?.passCharacterNum,
      tenant_password_require_special: previewData?.passCharacterSp,
      tenant_password_require_lowercase: previewData?.passCharacterLo,
      tenant_password_require_uppercase: previewData?.passCharacterUp,
      tenant_temp_pass_expiry: parseInt(previewData?.passwordLength),
      tenant_mfa: previewData?.multiFactorAuth ?"ON":"OPTIONAL", 
      tenant_remember_device: previewData?.rememberUserDevice ?true:false,
      tenant_web_client_access: previewData?.accessToWebClient ?true:false,
    }; 
    console.log("tenantStagingRequest",tenantStagingRequest)

    let data = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenant`,
      method: "POST",
      defaultTen: tenant_id,
      body: tenantStagingRequest,
    };

    const response = await apiRequest(data);
    if (response) {
      brandRef.current.load();
      setOpenDialog(false);
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      brandRef.current.load();
      // toast.error("Something wrong", {
      //   position: "top-right",
      // });
    }
  };

  return (
    <div
      className="main-container-tenet"
      style={{ display: "flex", flexDirection: "row", width: "100%" }}
    >
      {openDialog && (
        <PreviewModel
          data={previewData}
          onClose={() => setOpenDialog(false)}
          onSubmit={() => {
            setOpenDialog(false);
            handleSubmit();
          }}
        />
      )}
      <ToastContainer />
      {show && (
        <BrandingForm
          width={width === 70 ? width - 10 : width / 1.7}
          validateAccessPolicyAndSubmit={validateAccessPolicyAndSubmit}
          checkAccessValidation={checkAccessValidation}
          ref={brandRef}
        />
      )}
      <div style={{ width: "10px" }} />
      {show && (
        <AccessPolicyForm
          width={width === 70 ? width - 10 : width / 1.7}
          ref={accessRef}
        />
      )}
    </div>
  );
};

export default CreateTenet;
