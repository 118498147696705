import React, { useState } from "react";
import "./campaignStatus.css";
import { useSpring, animated } from "@react-spring/web";
import Close from "@mui/icons-material/Close";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
  FaFileDownload,
} from "../../../constants/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const CampaignStatus = ({ records, type, openRecordList, onClose }) => {
  const slideIn = useSpring({
    transform: openRecordList ? "translateY(0%)" : "translateY(100%)",
    opacity: openRecordList ? 1 : 0,
  });

  const width = useSelector((state) => state.width.width);
  const boxWidth = (window.innerWidth - width) / 4;

  const [filterType, setFilterType] = useState("All");

  const dummyData = [
    { vin: "djjdjjd", status: "Pending", date: "12-10-25", percentage: "20%" },
    { vin: "djjdjjd", status: "Pending", date: "12-10-25", percentage: "20%" },
    { vin: "djjdjjd", status: "Pending", date: "12-10-25", percentage: "20%" },
    { vin: "djjdjjd", status: "Pending", date: "12-10-25", percentage: "20%" },
  ];
  const percentage = 66;

  return (
    <animated.div className="bottom-sheet" style={slideIn}>
      <div className="sheet-header">
        <div
          style={{
            display: "flex",
            width: 300,
            flexDirection: "column",
            backgroundColor: "white",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <div style={{ fontSize: 12, fontFamily: "Rubik" }}>
            Campaign Name:{" "}
          </div>
          <div style={{ fontSize: 12, fontFamily: "Rubik" }}>Campaign ID: </div>
          <div style={{ fontSize: 12, fontFamily: "Rubik" }}>Date: </div>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              width: 80,
              border: "1px solid #19172C",
              backgroundColor: filterType == "All" ? "#19172C" : "#fff",
              color: filterType == "All" ? "#fff" : "#000",
              cursor: "pointer",
            }}
            onClick={()=>setFilterType("All")}
          >
            All: 20
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "Success" ? "#d4f8d4" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #d4f8d4",
            }}
            onClick={()=>setFilterType("Success")}
          >
            Success: 10
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "InProgress" ? "#add8e6" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #add8e6",
            }}
            onClick={()=>setFilterType("InProgress")}
          >
            In Progress: 10
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "Failed" ? "#FCC981" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #FCC981",
            }}
            onClick={()=>setFilterType("Failed")}
          >
            Failed: 10
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "Cancelled" ? "#ffcccb" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #ffcccb",
            }}
            onClick={()=>setFilterType("Cancelled")}
          >
            Cancelled: 10
          </h2>
        </div>

        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <Close />
        </div>
      </div>
      <div className="sheet-content">
        <div
          className="single-container"
          style={{ backgroundColor: "#A6B8DC" }}
        >
          <div
            style={{
              width: 100,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 50,
            }}
          >
            Sl.No
          </div>
          <div
            style={{
              width: boxWidth,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Vin
          </div>
          <div
            style={{
              width: boxWidth + 150,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Status
          </div>
          <div
            style={{
              width: boxWidth / 1.5,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Percentage Completed
          </div>
          <div
            style={{
              width: boxWidth / 2.5,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Date
          </div>
        </div>
        {dummyData && dummyData.length ? (
          dummyData.map((item, index) => (
            <div
              className="single-container"
              style={{
                height: 50,
                backgroundColor: index % 2 == 0 ? "#f2f2f2" : "#fff",
              }}
              key={item?.vin}
            >
              <div
                style={{
                  width: 100,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {index + 1}
              </div>
              <div
                style={{
                  width: boxWidth,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.vin}
              </div>
              <div
                style={{
                  width: boxWidth + 150,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.status}
              </div>
              <div
                style={{
                  width: boxWidth / 1.5,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: 40, height: 40 }}>
                  <CircularProgressbar
                    value={66}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0.25,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#19172C`,
                      textColor: "#000",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
              <div
                style={{
                  width: boxWidth / 2.5,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.date}
              </div>
            </div>
          ))
        ) : (
          <div>No Data Found</div>
        )}
      </div>

      <div className="pagination-box">
        <ReactPaginate
          previousLabel={
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          nextLabel={
            <ArrowForwardIosOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          breakLabel={"...."}
          marginPagesDisplayed={2}
          pageCount={1}
          onPageChange={"handlePageClick"}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttns"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </animated.div>
  );
};

export default CampaignStatus;
