import React from "react";
import { useSelector } from "react-redux";
import "./header.css";

const Header = ({tableType}) => {
  const width = useSelector((state) => state.width.width);
  return (
    <div
      style={{ width: window.innerWidth - width - 30 }}
      className="main-header-container"
    >
      <input
        type="text"
        placeholder="Search Tenant"
        className="search-box-tenant"
      />
      <div className="filter-container">
        Filter Table
        <select className="status-selector" onChange={tableType}>
          <option value="pending">Pending</option>
          <option value="success">Success</option>
        </select>
      </div>
    </div>
  );
};

export default Header;
