import React, { useEffect, useState, useContext } from "react";
import log from "loglevel";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { FaBackward, FaForward } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./viewVehicleModel.css";
import BasicModal from "../../modals/basicModal";
import ConfirmationModal from "../../modals/confirmationModal";
import SuccessDeleteModal from "../../modals/successModalDelete";
import ErrorModal from "../../modals/errorModal";
import {
  pathParams,
  GET,
  additionalParams,
  body,
} from "../../constants/constants";
import { vehicle_models } from "../../constants/endpoint";
import Handler from "../../components/handler";

let apigClientFactory = require("aws-api-gateway-client").default;

export default function ViewVehicleModel() {
  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";
  const CURRENT_USER_TYPE = CognitoRole;

  const tenant = JSON.parse(localStorage.getItem("TenantID"));
  const superAdminRole = "superAdminRole";
  const adminrole = "adminrole";


  // const accessKeyId = JSON.parse(localStorage.getItem("accessKey"));
  // const SecretKey = JSON.parse(localStorage.getItem("secretAccessKey"));
  // const SessionToken = JSON.parse(localStorage.getItem("sessionToken"));
  // const region = JSON.parse(localStorage.getItem("region"));

  console.log(accessKeyId);
  console.log(SecretKey);
  console.log(SessionToken);
  console.log(region);

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  console.log(config);

  const apigClient = apigClientFactory.newClient(config);
  console.log(apigClient);

  //to handle data received
  const [vehicleModels, setVehicleModels] = useState([]);
  //for search operation
  const [value, setvalue] = useState("");
  //to handle dialog box and responses
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState("");
  const [openDialog, setOpenDialog] = useState(false); //success dialog
  const [openErrorDialog, setOpenErrorDialog] = useState(false); //Error dialog
  //handling confirmation dialog box
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle1: "",
  });

  const onHandleClose = () => {
    setOpen(false); //closes dialog
  };

  const onHandleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onHandleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const navigate = useNavigate();

  const getVehicle = async () => {
    let pathTemplate = "/vehicleservice/vehiclemodel";
    let method = "GET";
    let pathParams = {
      //This is where path request params go.
      //userId: '1234',
    };
    let additionalParams = {
      headers: {
        "tenant-id": tenant,
      },
    };

    let body = {
      //This is where you define the body of the request
    };
    // apiRequest(pathParams, pathTemplate, method, additionalParams, body);

    // try {
    //   console.log('calling apigClient')
    //   console.log(pathParams, pathTemplate, method, additionalParams, body);
    //   await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
    //       .then(function(result){
    //         console.log(result);
    //         // console.log(JSON.stringify(result));
    //         const Data = result.data.vehicle_models || result.data;
    //         if(Array.isArray(Data)) {
    //           setVehicleModels(Data);
    //         }
    //         console.log("SUCCESS RESPONSE",result);
    //       }).catch( function(error){
    //         console.log("FAILURE RESPONSE",error);
    //         return JSON.stringify(error);
    //       });
    //  }
    //     //to handle responses in axios request
    //     catch (err) {
    //       log.error("Could not send the request");
    //     }
  };

  useEffect(() => {
    getVehicle();
    console.log("useeffect");
  }, []);

  const handleDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      let pathParams = {};
      let additionalParams = {
        headers: {
          "tenant-id": tenant,
        },
      };

      let body = {
        //This is where you define the body of the request
      };
      let pathTemplate = `/vehicleservice/vehiclemodel/${id}`;
      let method = "DELETE";
      await apigClient
        .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
        .then((result) => {
          console.log(result);
          if (result.status === 200) {
            setOpenDialog(true);
          } else if (result.data.status === 500) {
            setOpenErrorDialog(true);
          } else {
            setOpen(true);
            setChildren(result.data.msg || result.data);
          }
          getVehicle();
        });
    } catch (err) {
      log.error("error");
      // log.error("Axios error",err.message);
      // setOpen(true);     //opens dialog
      // setChildren(err.message); //displays response in dialog
    }
  };

  const [pageNumber, setPageNumber] = useState(0);
  const vehicleModelsPerPage = 10;
  const pagesVisited = pageNumber * vehicleModelsPerPage;
  const pageCount = Math.ceil(vehicleModels.length / vehicleModelsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  //function to convert UTC to local time
  function convertUTCDateToLocalDate(val) {
    let dateLocal = new Date(val);
    let timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    let result = timestamp.toLocaleString();
    return result;
  }

  return (
    // <Handler params={{ pathParams, vehicle_models, GET, additionalParams, body }}>
    //   {({ data,apiRequest }) => {
       
    //     return (
          <>
            {
              <BasicModal open={open} handleClose={onHandleClose}>
                <p>{children}</p>
              </BasicModal>
            }

            {openDialog && (
              <SuccessDeleteModal
                openDialog={openDialog}
                onHandleClose={onHandleCloseDialog}
              />
            )}

            {openErrorDialog && (
              <ErrorModal
                openDialogError={openErrorDialog}
                onHandleCloseErrorDialog={onHandleCloseErrorDialog}
              />
            )}
            <ConfirmationModal
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
            <div className="heading">
              <h5>List of Available Vehicle Models</h5>
              <input
                style={{
                  maxWidth: "250px",
                  float: "right",
                  padding: "5px 10px",
                  border: "2px solid #080808",
                  marginLeft: "25%",
                }}

                type="text"
                className="form-control"
                placeholder="Search Model name..."
                value={value}
                onChange={(e) => setvalue(e.target.value)}
              />
            </div>

            <br></br>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ maxHeight: 20 }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Sl.No
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Model Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Engine Type
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Year Of introduction
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Description
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Created At
                      </TableCell>
                      <TableCell sx={{ fontWeight: 700 }} align="center">
                        Updated At
                      </TableCell>
                      {((CognitoRole &&
                        CURRENT_USER_TYPE.localeCompare(
                          superAdminRole,
                          undefined,
                          {
                            sensitivity: "base",
                          }
                        ) === 0) ||
                        (CognitoRole &&
                          CURRENT_USER_TYPE.localeCompare(
                            adminrole,
                            undefined,
                            {
                              sensitivity: "base",
                            }
                          ) === 0)) && (
                        <>
                          <TableCell sx={{ fontWeight: 700 }} align="center">
                            Update
                          </TableCell>
                          <TableCell sx={{ fontWeight: 700 }} align="center">
                            Delete
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vehicleModels &&
                      vehicleModels
                        .slice(
                          pagesVisited,
                          pagesVisited + vehicleModelsPerPage
                        )
                        .filter((vehicleModels) => {
                          if (value === "") {
                            return vehicleModels;
                          } else if (
                            vehicleModels.model_name
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          ) {
                            return vehicleModels;
                          }
                          return null;
                        })
                        .map((vehicleModel, index) => {
                          return (
                            <TableRow
                              style={
                                index % 2
                                  ? {
                                      backgroundColor:
                                        "rgba(72, 34, 180, 0.04)",
                                    }
                                  : { backgroundColor: "white" }
                              }
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={vehicleModel.id}
                            >
                              <TableCell
                                key={vehicleModel.id}
                                align="center"
                                component="th"
                                scope="row"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="center">
                                {vehicleModel.model_name}
                              </TableCell>
                              <TableCell align="center">
                                {vehicleModel.engine_type}
                              </TableCell>
                              <TableCell align="center">
                                {vehicleModel.year_of_introduction}
                              </TableCell>
                              <TableCell align="center">
                                {vehicleModel.description}
                              </TableCell>
                              <TableCell align="center">
                                {convertUTCDateToLocalDate(
                                  vehicleModel.created_at
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {convertUTCDateToLocalDate(
                                  vehicleModel.updated_at
                                )}
                              </TableCell>
                              {((CognitoRole &&
                                CURRENT_USER_TYPE.localeCompare(
                                  superAdminRole,
                                  undefined,
                                  {
                                    sensitivity: "base",
                                  }
                                ) === 0) ||
                                (CognitoRole &&
                                  CURRENT_USER_TYPE.localeCompare(
                                    adminrole,
                                    undefined,
                                    {
                                      sensitivity: "base",
                                    }
                                  ) === 0)) && (
                                <>
                                  <TableCell align="center">
                                    <FiEdit
                                      style={{
                                        fontSize: "120%",
                                        color: "#4822B4",
                                      }}
                                      title={"Update"}
                                      onClick={() =>
                                        navigate(
                                          `/vehicleModels/UpdateVehicleModel/${vehicleModel.model_id}`
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <MdDeleteOutline
                                      style={{
                                        fontSize: "140%",
                                        color: "#4822B4",
                                      }}
                                      title={"Delete"}
                                      onClick={() =>
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: "Delete Data",
                                          subtitle1:
                                            "Are You Sure To Delete Selected Record?",
                                          onConfirm: () => {
                                            handleDelete(vehicleModel.model_id);
                                          },
                                        })
                                      }
                                    />
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br></br>
              <div className="pagenate">
                <h6>Available Vehicle Models</h6>
                <div>
                  {/* pagination for table */}
                  <ReactPaginate
                    previousLabel={<FaBackward />}
                    nextLabel={<FaForward />}
                    breakLabel={"...."}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttns"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
                <h6>Total Records : {vehicleModels.length}</h6>
              </div>
            </Paper>

            <br></br>
            
            {((CognitoRole &&
              CURRENT_USER_TYPE.localeCompare(superAdminRole, undefined, {
                sensitivity: "base",
              }) === 0) ||
              (CognitoRole &&
                CURRENT_USER_TYPE.localeCompare(adminrole, undefined, {
                  sensitivity: "base",
                }) === 0)) && (
              <div>
                <button 
                  type="submit"
                  className="btn btn-dark"
                  style={{
                    minWidth: "30%",
                    margin: "auto",
                    display: "block",
                  }}
                  onClick={() => navigate("/vehicleModels/AddVehicleModel")}
                >
                  Create Vehicle Model
                </button>
              </div>
            )}
          </>
        );
    //   }}
    // </Handler>
  // );
}
