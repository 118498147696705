import React, { useState } from "react";
import TableListHistory from "./table-list/table-list-history";
import TableListSubmitted from "./table-list/table-list-submitted";

import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/melecules/main-header/main-header";
import CreateTenet from "../create-tenet/create-tenet";
import { useEffect } from "react";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import Header from "./header/header";
function OnboardTenet() {
  const [activeTab, setActiveTab] = useState(1);
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const [successData,setSuccessData] = useState([]);
  const [statusHistory,setStatusHistory] = useState([]);
  const [type,setTableType] = useState("pending")
  const defaultTenant = process.env.REACT_APP_USER_ROLE;
  const navigate = useNavigate();
  const Tabs = [
    { id: 1, title: "All Tenant" },
    { id: 2, title: "Onboard Tenant" },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let data1 = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenantrequest`,
      method: "GET",
      body: {},
    };
    let data2 = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenant`,
      method: "GET",
      body: {}
    };
    try {
      const [table1, table2] = await Promise.all([
        apiRequest(data1),
        apiRequest(data2),
      ]);
      

      const combinedData = table1.map((request) => ({
        requested_on: request.requestedOn,
        requested_by: request.requestedBy,
        created_on: request.createdOn,
        created_by: request.createdBy,
        staging_id: request.tenantStagingId,
        status: request.status,
        tenant_domain: table2.find(
          (tenant) => tenant.tenantStagingId === request.tenantStagingId
        ).tenantDomain,
        tenant_description: table2.find(
          (tenant) => tenant.tenantStagingId === request.tenantStagingId
        ).tenantDescription,
        tenant_id: table2.find(
          (tenant) => tenant.tenantStagingId === request.tenantStagingId
        ).tenantId,
      }));
      const filteredData = combinedData.filter((item) => item.status === "P");
      const filteredData2 = combinedData.filter((item) => item.status === "S");

      setSuccessData(filteredData);
      setStatusHistory(filteredData2);
    } catch (error) {
      console.log("Error fetching tenant data", error);
    }
  };

  const tableType =(type)=>{
    setTableType(type.target.value)
  }

  const Tab = {
    1: (
      <div>
        <Header tableType={tableType}/>
        {
          type == "pending"?
          <TableListSubmitted title={"Submitted Request"} successData={successData}/>
          :
          <TableListHistory title={"Submitted Request"} successData={statusHistory}/>
        }
      </div>
    ),
    2: <CreateTenet />,
  };

  const headerTitle = {
    1: "Onboard Status",
    2: "Create Tenet",
    3: "Update Vehicle Model",
  };

  const clickTab = (id, data) => {
    setActiveTab(id);
  };

  return (
    <div>
      <MainHeader
        title={headerTitle[activeTab]}
        searchBar={false}
        button={true}
        onClick={clickTab}
        tab={Tabs}
      />
      {Tab[activeTab]}
    </div>
  );
}

export default OnboardTenet;
