import { Auth, Amplify } from "aws-amplify";
import {
  getCredentials,
  getLocalStorageData,
  setLocalStorage,
  getRefreshTime,
} from "../../utilities/helper";
import jwtDecode from "jwt-decode";
var apigClientFactory = require("aws-api-gateway-client").default;

const getConfigData = () => {
  const { tenant_id, userpool_id, identity_pool_id, client_id, region } =
    getCredentials();

  const amplifyConfig = getLocalStorageData("amplifyConfig");

  const isConfigChanged =
    amplifyConfig &&
    tenant_id &&
    (amplifyConfig.aws_project_region !== region ||
      amplifyConfig.aws_cognito_identity_pool_id !== identity_pool_id ||
      amplifyConfig.aws_user_pools_id !== userpool_id ||
      amplifyConfig.aws_user_pools_web_client_id !== client_id);

  if (
    (userpool_id && identity_pool_id && client_id && region && tenant_id) ||
    isConfigChanged
  ) {
    setLocalStorage("amplifyConfig", Amplify.configure());
    setLocalStorage("TenantID", tenant_id);
    return {
      aws_project_region: region,
      aws_cognito_identity_pool_id: identity_pool_id,
      aws_cognito_region: region,
      aws_user_pools_id: userpool_id,
      aws_user_pools_web_client_id: client_id,
    };
  } else if (amplifyConfig) {
    return amplifyConfig;
  } else {
    return {
      aws_project_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
      aws_cognito_identity_pool_id:
        process.env.REACT_APP_DEFAULT_IDENTITYPOOL_ID,
      aws_cognito_region: process.env.REACT_APP_DEFAULT_AWS_CLI,
      aws_user_pools_id: process.env.REACT_APP_DEFAULT_USERPOOL_ID,
      aws_user_pools_web_client_id: process.env.REACT_APP_DEFAULT_WEBCLIENT_ID,
    };
  }
};

const getTokens = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const refreshTokens = session.getRefreshToken().getToken();
    const decodedIdToken = jwtDecode(idToken);
    const region = decodedIdToken.iss.split(".")[1];
    const groups = decodedIdToken["cognito:roles"];
    const userRole = groups ? groups[0] : null;
    const finalRoles = userRole.split("/");
    const userrole = finalRoles[1];
    let exp = decodedIdToken.exp;
    return {
      userRole,
      exp,
      ...{
        accessToken: accessToken,
        idToken: idToken,
        userrole: userrole,
        user:decodedIdToken?.email,
        refreshTokens:refreshTokens,
        region: region,
        error:false
      },
    };
  } catch (error) {
    return {error}
  }
};

const getTokenCredentials = async () => {
  try {
    const credentials = await Auth.currentCredentials();
    const accessKeyId = credentials.accessKeyId;
    const secretAccessKey = credentials.secretAccessKey;
    const sessionToken = credentials.sessionToken;
    return {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };
  } catch (error) {
    console.log("Error retrieving credentials:", error);
  }
};

const refreshToken = async () => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    const decodedIdToken = jwtDecode(idToken);
    const region = decodedIdToken.iss.split(".")[1];
    const groups = decodedIdToken["cognito:roles"];
    const userRole = groups ? groups[0] : null;
    const finalRoles = userRole.split("/");
    const userrole = finalRoles[1];
    return {
      userrole,
      ...{ accessToken, idToken, userrole, region },
    };
  } catch (error) {
    console.log("Error refreshing token:", error);
  }
};

const configApiClient = (config) => {
  const apiClient = apigClientFactory.newClient(config);
  return apiClient;
};

export {
  getConfigData,
  getTokens,
  getTokenCredentials,
  refreshToken,
  configApiClient,
};
