import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./uploadHistory.css";
import FormContainer from "../../../components/organizer/form-container/form-container";
import SingleList from "./single-list";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import useStatusStore from "../../../services/zustand/status-popup";
import { useSelector } from "react-redux";
import RecordList from "./record-list";
import { useSpring, animated } from "@react-spring/web";

const UploadHistory = (props, ref) => {
  const { loading, apiRequest } = useAwsApiRequest();
  const [history, setHistory] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [recordType, setRecordType] = useState("");
  const [openRecordList, setOpenRecordList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState("Last 7 Days");

  const width = useSelector((state) => state.width.width);
  const filterTypes = ["Last 7 Days", "By Date"];

  useEffect(() => {
    getAllData();
  }, []);

  const popUpAnimation = useSpring({
    transform: isOpen ? `scale(1)` : `scale(0)`,
    opacity: isOpen ? 1 : 0,
    config: { tension: 220, friction: 20 },
  });

  useImperativeHandle(ref, () => ({
    loadData: () => {
      getAllData();
    },
  }));

  function getFileStatus(filename) {
    // Retrieve the current data from localStorage, if any
    const storedData = JSON.parse(localStorage.getItem("fileStatusData")) || [];

    // Find the file with the given filename
    const file = storedData.find((file) => file.filename === filename);

    // If the file is found, return its status, otherwise return null
    return file ? file.status : null;
  }

  function groupDataByFilename(data) {
    const groupedData = [];

    const addToGroup = (record, status) => {
      let group = groupedData.find(
        (group) => group.filename === record?.filename
      );

      let submitted = getFileStatus(record.filename);
      if (!group) {
        group = {
          filename: record.filename,
          date: record?.created_at,
          isSubmitted: submitted ? true : false,
          data: [
            {
              validRecords: [],
              invalidRecords: [],
            },
          ],
        };
        groupedData.push(group);
      }
      group.data[0][status].push(record);
    };

    if (data.validRecords) {
      data.validRecords.forEach((record) => addToGroup(record, "validRecords"));
    }
    if (data.invalidRecords) {
      data.invalidRecords.forEach((record) =>
        addToGroup(record, "invalidRecords")
      );
    }
    groupedData.sort((a, b) => new Date(b.date) - new Date(a.date));
    console.log("groupedData", groupedData);
    return groupedData;
  }

  const dummyData = {
    validRecords: [
      {
        id: 7,
        tenant_id: "823e4567-e89b-12d3-a456-426614174007",
        filename: "file-2",
        username: "mohan",
        vin: "1HGCM82633A004362",
        model_name: "model-10",
        fleet_name: "fleet-10",
        thing_name: "thing-10",
        firmware_version: "5.0.0",
        version: "v5.0",
        region: "Jaipur",
        onboarded_status: "inactive",
        status: "valid",
        error_type: "none",
        error_details: null,
        s3_url: null,
        created_at: "2024-08-09T07:31:45.066Z",
        updatedAt: "2024-08-09T07:31:45.066Z",
      },
      {
        id: 8,
        tenant_id: "823e4567-e89b-12d3-a456-426614174007",
        filename: "file-3",
        username: "mohan",
        vin: "1HGCM82633A004361",
        model_name: "model-8",
        fleet_name: "fleet-8",
        thing_name: "thing-8",
        firmware_version: "4.0.1",
        version: "v4.1",
        region: "Ahmedabad",
        onboarded_status: "active",
        status: "valid",
        error_type: "none",
        error_details: null,
        s3_url: null,
        created_at: "2024-09-09T07:31:45.066Z",
        updatedAt: "2024-08-09T07:31:45.066Z",
      },
      {
        id: 7,
        tenant_id: "823e4567-e89b-12d3-a456-426614174007",
        filename: "file-7",
        username: "mohan",
        vin: "1HGCM82633A004362",
        model_name: "model-10",
        fleet_name: "fleet-10",
        thing_name: "thing-10",
        firmware_version: "5.0.0",
        version: "v5.0",
        region: "Jaipur",
        onboarded_status: "inactive",
        status: "valid",
        error_type: "none",
        error_details: null,
        s3_url: null,
        created_at: "2024-08-10T07:31:48.066Z",
        updatedAt: "2024-08-09T07:31:45.066Z",
      },
    ],
    invalidRecords: [
      {
        id: 5,
        tenant_id: "a23e4567-e89b-12d3-a456-426614174009",
        filename: "file-1",
        username: "mohan",
        vin: "1HGCM82633A004360",
        model_name: "model-11",
        fleet_name: "fleet-11",
        thing_name: "thing-11",
        firmware_version: "5.0.0",
        version: "v5.0",
        region: "Jaipur",
        onboarded_status: "inactive",
        status: "invalid",
        error_type: "databaseerror",
        error_details: "Validation error",
        s3_url: null,
        created_at: "2024-10-09T07:31:45.066Z",
        updatedAt: "2024-08-09T08:02:50.804Z",
      },
      {
        id: 6,
        tenant_id: "a23e4567-e89b-12d3-a456-426614174009",
        filename: "file-3",
        username: "mohan",
        vin: "1HGCM82633A004363",
        model_name: "model-12",
        fleet_name: "fleet-12",
        thing_name: "thing-12",
        firmware_version: "4.0.1",
        version: "v4.1",
        region: "Ahmedabad",
        onboarded_status: "active",
        status: "invalid",
        error_type: "databaseerror",
        error_details: "Validation error",
        s3_url: null,
        created_at: "2024-11-09T07:31:45.066Z",
        updatedAt: "2024-08-09T08:02:50.804Z",
      },
    ],
  };

  const getAllData = async () => {
    const groupedData = groupDataByFilename(dummyData);
    setHistory(groupedData);
    return;

    let data = {
      pathParam: {},
      endpoint: "http://localhost:3000/vehicleservice/vehicle/vehicledetails",
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);
    if (response?.data) {
      if (response?.data?.validRecords || response?.data?.invalidRecords) {
        const groupedData = groupDataByFilename(response?.data);
        setHistory(groupedData);
      }
    }
  };

  const updateStatus = (filename) => {
    setHistory((prevData) =>
      prevData.map((item) =>
        item.filename === filename ? { ...item, isSubmitted: true } : item
      )
    );
  };

  const showRecords = (type, records) => {
    setSelectedRecords(records);
    setRecordType(type);
    setOpenRecordList(true);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const selectFilter = (type) => {
    setSelectedFilterType(type);
    setIsOpen(false);
  };

  const filterComponent = () => {
    return (
      <div className="filter-container">
        <div className="filter-button" onClick={togglePopup}>
          {selectedFilterType}
        </div>
        {selectedFilterType == "By Date" ? <div style={{ width: 5 }} /> : null}
        {selectedFilterType == "By Date" ? (
          <div className="filter-button" style={{padding:"2px"}}>
            <input
              type="date"
              style={{border:"none",borderRadius:2}}
              // value={selectedDate}
              // onChange={handleDateChange}
              // className="date-picker-input"
            />
          </div>
        ) : null}

        <animated.div
          style={{
            ...popUpAnimation,
            position: "absolute",
            background: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            // padding: "5px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          {filterTypes.map((type) => (
            <div
              key={type}
              className="filter-button"
              style={{
                cursor: "pointer",
                border: "1px solid #eee",
                backgroundColor: "white",
                margin: "5px",
                height: "30px",
                color: "black",
                fontFamily: "Rubik",
              }}
              onClick={() => {
                selectFilter(type);
              }}
            >
              {type}
            </div>
          ))}
        </animated.div>
      </div>
    );
  };

  const boxWidth = (window.innerWidth / 1.4 - width) / 6;

  return (
    <div style={{ marginLeft: "10px" }}>
      <FormContainer
        minusWidth={width}
        header={true}
        title={"History"}
        divWidth={1.33}
        CustomComponent={filterComponent}
      >
        <div
          className="list-container"
          style={{
            justifyContent:
              loading || history?.length == 0 ? "center" : "start",
            // overflow: "hidden",
          }}
        >
          {loading ? (
            <div className="spinner"></div>
          ) : (
            <>
              <div
                className="single-container"
                style={{ backgroundColor: "#A6B8DC" }}
              >
                <div className="slBox" style={{ width: boxWidth / 3 }}>
                  Sl.No
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  File Name
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Total Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Invalid Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Valid Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Submitted Records
                </div>
                <div className="slBox" style={{ width: boxWidth }}>
                  Time
                </div>
              </div>
              {history?.length
                ? history.map((x, index) => (
                    <SingleList
                      key={x?.filename}
                      item={x}
                      updateStatus={updateStatus}
                      index={index}
                      boxWidth={boxWidth}
                      showRecords={showRecords}
                    />
                  ))
                : "No Data Found"}
            </>
          )}
        </div>
      </FormContainer>
      <RecordList
        openRecordList={openRecordList}
        onClose={() => setOpenRecordList(false)}
        records={selectedRecords}
        type={recordType}
      />
    </div>
  );
  
};

export default forwardRef(UploadHistory);
