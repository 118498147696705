import React, { useEffect, useState } from "react";
import "./uploadHistory.css";
import {
  FaFileDownload,
  FaEye,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useStatusStore from "../../../services/zustand/status-popup";

const SingleList = ({ item, updateStatus, index, boxWidth, showRecords }) => {
  const { loading, apiRequest } = useAwsApiRequest();
  const [isSubmitted, setSubmitted] = useState(item?.isSubmitted);
  const setMessage = useStatusStore((state) => state?.setMessage);

  function transformData(records) {
    return records.map((record) => ({
      tenant_id: record.tenant_id,
      vin: record.vin,
      model_name: record.model_name,
      fleet_name: record.fleet_name,
      thing_name: record.thing_name,
      firmware_version: record.firmware_version,
      version: record.version,
      region: record.region,
      onboarded_status: record.onboarded_status,
    }));
  }

  function saveOrUpdateFileStatus(fileObj) {
    // Retrieve the current data from localStorage, if any
    let storedData = JSON.parse(localStorage.getItem("fileStatusData")) || [];

    // Check if the filename already exists in the stored data
    const existingFileIndex = storedData.findIndex(
      (file) => file.filename === fileObj.filename
    );

    if (existingFileIndex !== -1) {
      // If the file exists, update the status
      storedData[existingFileIndex].status = fileObj.status;
    } else {
      // If the file doesn't exist, add it to the stored data
      storedData.push(fileObj);
    }

    // Save the updated data back to localStorage
    localStorage.setItem("fileStatusData", JSON.stringify(storedData));
  }

  const validateAndUpload = async () => {
    // let history = { filename: item?.filename, status: "Submitted" };
    // saveOrUpdateFileStatus(history);
    // updateStatus(item?.filename);
    // return
    if (item?.isSubmitted) {
      return;
    }
    const body = transformData(item?.data[0]?.validRecords);
    const requestData = {
      pathParam: {},
      endpoint: "http://localhost:3000/vehicleservice/vehicle/upload",
      method: "POST",
      body: body,
      queryParams: { filename: item?.filename },
    };

    const response = await apiRequest(requestData);

    if (response) {
      console.log("response", response);
      setMessage(null, `Valid Data Successfully Submitted`, "success");
      setSubmitted(true);
      let history = { filename: item?.filename, status: "Submitted" };
      saveOrUpdateFileStatus(history);
      updateStatus(item?.filename);
    }
  };

  const customFilename = `${item?.filename?.replace(
    /\.[^/.]+$/,
    ""
  )}-invalid-records.csv`;
  console.log("item?.date", new Date(item?.date));
  return (
    <div className="single-container">
      <div
        className="single-container"
        style={{ backgroundColor: index % 2 == 0 ? "white" : "#f2f2f2" }}
      >
        <div
          className="slBox"
          style={{ width: boxWidth / 3, fontSize: 11, fontWeight: "500" }}
        >
          {index + 1}
        </div>
        <div
          className="slBox"
          style={{
            width: boxWidth,
            fontSize: 11,
            fontWeight: "500",
            justifyContent: "flex-start",
            paddingLeft: 10,
          }}
        >
          {item?.filename}
        </div>
        <div
          className="slBox"
          style={{
            width: boxWidth,
            fontSize: 11,
            fontWeight: "500",
            justifyContent: "center",
            textDecoration:
              item?.data[0]?.validRecords?.length +
              item?.data[0]?.invalidRecords?.length
                ? "underline"
                : null,
            color:
              item?.data[0]?.validRecords?.length +
              item?.data[0]?.invalidRecords?.length
                ? "blue"
                : "black",
            cursor: "pointer",
          }}
        >
          {item?.data[0]?.validRecords?.length +
            item?.data[0]?.invalidRecords?.length}
        </div>
        <div
          className="slBox"
          style={{
            width: boxWidth,
            fontSize: 11,
            fontWeight: "500",
            justifyContent: "center",
            textDecoration: item?.data[0]?.invalidRecords?.length
              ? "underline"
              : null,
            color: item?.data[0]?.invalidRecords?.length ? "blue" : "black",
            cursor: "pointer",
          }}
          onClick={()=>showRecords("invalid",item?.data[0]?.invalidRecords)}
        >
          {item?.data[0]?.invalidRecords?.length}
          {/* <ArrowForwardIosOutlinedIcon style={{ fontSize: 12 }} /> */}
        </div>
        <div
          className="slBox"
          style={{
            width: boxWidth,
            fontSize: 11,
            fontWeight: "500",
            justifyContent: "center",
            textDecoration: item?.data[0]?.validRecords?.length
              ? "underline"
              : null,
            color: item?.data[0]?.validRecords?.length ? "blue" : "black",
            cursor: "pointer",
          }}
        >
          {item?.data[0]?.validRecords?.length}
        </div>
        <div
          className="slBox"
          style={{
            width: boxWidth,
            fontSize: 11,
            fontWeight: "500",
            justifyContent: "center",
            textDecoration: "underline",
            color: 0 ? "blue" : "black",
            cursor: "pointer",
          }}
        >
          0
        </div>
        <div
          className="slBox"
          style={{ width: boxWidth, fontSize: 11, fontWeight: "500" }}
        >
          {moment(new Date(item?.date)).format("MMM Do YYYY")}
        </div>
      </div>
      {/* <div className="title-header">
        <div className="title-text">{item?.filename}</div>
        <div className="title-text">
          {moment(new Date(item?.date)).format("MMMM Do YYYY, h:mm a")}
        </div>
      </div>
      <div className="item-box">
        <div className="status-box-history">
          <div className="box-sub-header" style={{ backgroundColor: "gray" }}>
            Total Data
          </div>
          <div className="status-count">
            {item?.data[0]?.validRecords?.length +
              item?.data[0]?.invalidRecords?.length}
          </div>
        </div>
        <div className="status-box-history">
          <div className="box-sub-header" style={{ backgroundColor: "red" }}>
            Invalid Data
          </div>
          <div className="status-count">
            {item?.data[0]?.invalidRecords?.length}
            <div style={{ width: "8px" }} />
            {item?.data[0]?.invalidRecords?.length ? (
              <CSVLink
                data={item?.data[0]?.invalidRecords}
                filename={customFilename}
              >
                <div className="file-download-box">
                  <FaFileDownload className="download-icon" />
                  {"Download"}
                </div>
              </CSVLink>
            ) : null}
          </div>
        </div>
        <div className="status-box-history">
          <div className="box-sub-header" style={{ backgroundColor: "green" }}>
            Valid Data
          </div>
          <div className="status-count">
            {item?.data[0]?.validRecords?.length}
          </div>
        </div>
        <div className="status-box-history">
          {item?.data[0]?.validRecords?.length ? (
            <div
              onClick={validateAndUpload}
              style={{
                width: "85px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: item?.isSubmitted ? "#f2f2f2" : "#19172C",
                borderRadius: "5px",
                marginTop: "5px",
                color: "white",
                fontSize: "10px",
                fontFamily: "Rubik",
              }}
            >
              {loading ? (
                <div className="spinner" style={{ marginBottom: "10px" }}></div>
              ) : item?.isSubmitted ? (
                <CheckCircleIcon
                  className="renew-icon"
                  style={{ color: "green" }}
                />
              ) : (
                "Submit"
              )}
            </div>
          ) : (
            <div
              style={{
                width: "85px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f2f2f2",
                borderRadius: "5px",
                marginTop: "5px",
                color: "gray",
                fontSize: "10px",
                fontFamily: "Rubik",

              }}
            >
              Submit
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default SingleList;
