import * as type from '../type';

const initialState = {
  cred: null,
}

export default function userType(state = initialState, action) {
  switch (action.type) {
    case type.SET_CRED:
      return {
        ...state,
        cred: action.payload
      }
    default:
      return state
  }
}