import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import log from "loglevel";
import "bootstrap/dist/css/bootstrap.min.css";

import "./createVehicleModel.css";
import BasicModal from "../../modals/basicModal";
import SuccessModal from "../../modals/successModal";
import ErrorModal from "../../modals/errorModal";
import Breadcrumb from "../../breadCrumbs/navigation";

let apigClientFactory = require('aws-api-gateway-client').default;

const UpdatevehicleModel = () => 
{

  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const [formData, updateFormData] = useState({
    model_name: "",
    description: "",
    year_of_introduction: "",
    engine_type: "",
  });

  const [ open, setOpen ] = useState(false);   //commom dialog
  const [ openDialog, setOpenDialog ] = useState(false);   //success dialog
  const [ openErrorDialog, setOpenErrorDialog ] = useState(false);  //Error dialog
  const [ children, setChildren ] = useState("");  //response to show in common dialog

  const onHandleClose =() => {
    setOpen(false);
  }

  const onHandleCloseDialog =() => {
    setOpenDialog(false);
  }

  const onHandleCloseErrorDialog =() => {
    setOpenErrorDialog(false);
  }

  const { model_id } = useParams();

  const { model_name, description, year_of_introduction, engine_type } =
    formData;

    //updating form data with the data entered by user
  const onHandleChange = (e) => 
  {
    updateFormData({      
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const loadVehicleModels = async () => 
  {
    let pathTemplate = `/vehicleservice/vehiclemodel/${model_id}`;
    let method = 'GET';
    let pathParams = {};
      let additionalParams = 
      {
        headers: {
          "tenant-id": tenant
        }      
      };
       
      let body = 
      {
        //This is where you define the body of the request
      };
      try {
        await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
            .then(function(resp){
              console.log(resp);
              const Data = resp.data.record;
              if((Data)) {
                updateFormData(Data);
              }
              if(resp.data.status === 404){
              setOpenErrorDialog(true);
              }
            }).catch( function(error){
              console.log("FAILURE RESPONSE",error);
            });
       }
          //to handle responses in axios request
          catch (err) {
            log.error("Could not send the request");
          }
  };

  useEffect(() => {
    loadVehicleModels();
  }, []);

  const AddUpdatedVehicleModels = async () => 
  {
      let pathParams = {};
      let pathTemplate = `/vehicleservice/vehiclemodel/${model_id}`;
      let method = 'PUT';
      let body = formData;
      let additionalParams = 
      {
        //If there are query parameters or headers that need to be sent with the request you can add them here
        headers: {
          "tenant-id": tenant
        }  
      };
  
    try {
      await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      // .put(`${url}/${model_id}`,formData)
      .then((resp) => {
        if(resp.data.status === 200) {
          setOpenDialog(true);
        }
        else if(resp.data.status === 500){
          setOpenErrorDialog(true);
        }
        else {
          setOpen(true);
          setChildren(resp.data.msg || resp.data);
        }
      });
    }
      catch (err) {
        log.error("error",err);
      
      }
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    AddUpdatedVehicleModels();
  };

  return (
    <>
    <Breadcrumb/>
      {
      open && (
        <BasicModal open={open} handleClose={onHandleClose}>
          <p>{children}</p>
        </BasicModal>
      )}
      {
      openDialog && (
        <SuccessModal openDialog={openDialog} onHandleClose ={onHandleCloseDialog} />
      )}
       {
      openErrorDialog && (
        <ErrorModal openDialogError={openErrorDialog} onHandleCloseErrorDialog  ={onHandleCloseErrorDialog} />
      )}
      <h2>Update Vehicle Model</h2>
      <div className="createvehicle">
        <form className="mt-3 mb-3" onSubmit={(e) => onHandleSubmit(e)}>
          <div className="col-sm-8">
            <label htmlFor="model_name">Model Name</label>
            <input
              type="string"
              className="form-control"
              id="model_name"
              value={model_name}
              disabled
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>Engine Type</label>
            <input
              type="string"
              className="form-control"
              value={engine_type}
              id="engine_type"
              disabled
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>Year of introduction</label>
            <input
              type="date"
              className="form-control"
              value={year_of_introduction}
              id="year_of_introduction"
              disabled
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="col-sm-8">
            <label>description</label>
            <textarea
              className="form-control"
              placeholder="Enter description"
              value={description}
              id="description"
              as="textarea"
              maxLength={70}
              rows={3}
              onChange={onHandleChange}
            />
          </div>
          <br />
          <div className="form-group row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-dark btn-block">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default UpdatevehicleModel;
