import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import log from "loglevel";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ViewProgress from "./viewProgress";
import { Button, ButtonGroup } from "@mui/material";
import SockJsClient from "react-stomp";
import TenantSubmitRequest from "./tenantOnboardingRequest";

let apigClientFactory = require('aws-api-gateway-client').default;

const StyledTableCell = styled(TableCell)(({ theme }) => 
({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#b0d5f2",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontFamily:'Rubik'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily:'Rubik'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function OnboardStatus() 
{

  const { cred } = "";
  const { tokens } = "";
  const awsKey = "cred.accessKeyId";
  const awsSecret = "cred.secretAccessKey";
  const sessionToken = "cred.sessionToken";
  const awsRegion = "tokens.region";
  const CognitoRole = "tokens.userrole";

  const config = {
    invokeUrl: process.env.REACT_APP_SERVICE_URL,
    region: region,
    accessKey: accessKeyId,
    secretKey: SecretKey,
    sessionToken: SessionToken,
  };

  const apigClient = apigClientFactory.newClient(config);

  const socketURL = process.env.REACT_APP_SCOKET_URL;
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]); //state for updating messages from websocket
  const [open, setOpen] = useState(false);  //handling dialog box
  const [statusHistory, setStatusHistory] = useState([]);
  const [successData, setSuccessData] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [selectedTenantID, setSelectedTenantID] = useState(null);
  const [selectedTenantStagingID, setSelectedTenantStagingID] = useState(null);

  
  const onHandleCloseProgressDialog = () => {
    setOpen(false); //closes dialog box
  }

  const onHandleCloseConfirmDialog = () => {
    setDialogIsOpen(false); 
  }

  const SOCKET_URL = `${socketURL}`;
   //to receive messges from webSocket on successful connection
   const onMessageReceived = (msg) => {
    setMessages(messages.concat(msg));
    const response = JSON.stringify(messages);
    localStorage.setItem(selectedTenantID, response);
    console.log(messages);
  };

  //called if webaSocket is connected
  let onConnected = () => {
    log.info("Connected!!");
  };

  let onDisconnect = () => {
    console.log("Disconnected!");
  }

  const customHeaders = {
    "Access-Control-Allow-Origin": "https://embfotaconnect.info"

  };

  const handleButtonClick = (tenantID, stagingID) => {
    setSelectedTenantID(tenantID);
    setSelectedTenantStagingID(stagingID);
    setDialogIsOpen(true);
  };

  const [ tenantID, setTenantID ] = useState("");
  const onHandleOpenStatusScreen = (tenant_id) => {
    setOpen(true);
    setTenantID(tenant_id);
  }

  function convertUTCDateToLocalDate(val) 
  {
    let dateLocal = new Date(val);
    let timestamp = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset()
    );
    let result = timestamp.toLocaleString();
    return result;
  }

  const userRole = process.env.REACT_APP_USER_ROLE;

  useEffect(() => 
  {
      async function getData() 
    {
      let pathTemplate1 = "/tenantservice/tenantrequest";
      let pathTemplate2 = "/tenantservice/tenant";
      let method = "GET";
      let pathParams = {
        //This is where path request params go.
        //userId: '1234',
      };
      let additionalParams = {
        headers: {
          "Content-Type": "application/json",
          "tenant-id": userRole,
        },
      };

      let body = {
        //This is where you define the body of the request
      };

      try {
        const [table1, table2] = await Promise.all([
          apigClient.invokeApi(pathParams, pathTemplate1, method, additionalParams, body),            
          apigClient.invokeApi(pathParams, pathTemplate2, method, additionalParams, body)       
        ]);
        const combinedData = table1.data.map((request) => ({
          requested_on: request.requestedOn,
          requested_by: request.requestedBy,
          created_on: request.createdOn,
          created_by: request.createdBy,
          staging_id: request.tenantStagingId,
          status: request.status,
          tenant_domain: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId
          ).tenantDomain,
          tenant_description: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId
          ).tenantDescription,
          tenant_id: table2.data.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId
          ).tenantId,
        }));
        // console.log(combinedData)
        const filteredData = combinedData.filter(item => item.status === 'P');
        const filteredData2 = combinedData.filter(item => item.status === 'S');
        setSuccessData(filteredData);
        setStatusHistory(filteredData2);
      }
       catch (error) 
       {
        log.error("Error fetching tenant data",error);
      }
    }
    getData();
  }, []);

  return (
    <div>
      <SockJsClient
        url={SOCKET_URL}
        headers= {customHeaders}
        topics={["/topic/group"]}
        onConnect={onConnected}
        onDisconnect={onDisconnect}
        onMessage={(msg) => onMessageReceived(msg)}
        debug={false}
      />
      <div>
        <h5>Submitted Request</h5>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 500 }}
            aria-label="customized table"
            stickyHeader
          >
            <TableHead position="sticky">
              <TableRow>
                <StyledTableCell align="center">Sl.No</StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>
                <StyledTableCell align="center">Brand details</StyledTableCell>
                <StyledTableCell align="center">Requested On</StyledTableCell>
                <StyledTableCell align="center">Requested By</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {successData.map((submittedDetails, index) => (
                <StyledTableRow key={submittedDetails.request_id}>
                  <StyledTableCell
                    key={submittedDetails.tenant_domain}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {submittedDetails.tenant_domain}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {submittedDetails.tenant_description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {convertUTCDateToLocalDate(submittedDetails.requested_on)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {"Super Admin"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {submittedDetails.status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <ButtonGroup
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                      onClick={() => handleButtonClick(submittedDetails.tenant_id,submittedDetails.staging_id)}
                        style={{ 
                          backgroundColor: "#000000",
                          fontFamily:'Rubik',
                          color: '#FFFFFF',
                          flex: 1,
                          borderRadius: "4px",
                          marginRight: "8px",
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                       onClick={() =>
                        navigate(
                          `/OnboardStatus/UpdateTenant/${submittedDetails.tenant_id}`
                        )
                      }
                        style={{
                          backgroundColor: "#424040",
                          fontFamily:'Rubik',
                          color: '#FFFFFF',
                          flex: 1,
                          borderRadius: "4px",
                          margin: "0 8px",
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => onHandleOpenStatusScreen(submittedDetails.tenant_id)}
                        style={{
                          backgroundColor: "#000000",
                          fontFamily:'Rubik',
                          color: '#FFFFFF',
                          flex: 1,
                          borderRadius: "4px",
                          marginLeft: "8px",
                        }}
                      >
                        Status
                      </Button>
                    </ButtonGroup>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br></br>
      <div>
        <h5>Request History</h5>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 500 }}
            aria-label="customized table"
            stickyHeader
          >
            <TableHead position="sticky">
              <TableRow>
                <StyledTableCell align="center">Sl.No.</StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>
                <StyledTableCell align="center">Brand details</StyledTableCell>
                <StyledTableCell align="center">Created On</StyledTableCell>
                <StyledTableCell align="center">Submitted By</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusHistory.map((historyDetails, index) => {
                return (
                  <StyledTableRow key={historyDetails.request_id}>
                    <StyledTableCell
                      key={historyDetails.tenant_domain}
                      align="center"
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {historyDetails.tenant_domain}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {historyDetails.tenant_description}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {convertUTCDateToLocalDate(historyDetails.created_on)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {"Super Admin"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {historyDetails.status}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table> 
        </TableContainer>
        {open && <ViewProgress tenantID={tenantID}
        open={open} handleClose={onHandleCloseProgressDialog} />}
        {dialogIsOpen && <TenantSubmitRequest 
        openDialog={dialogIsOpen} 
        tenantID={selectedTenantID} 
        stagingID={selectedTenantStagingID}
        onClose={onHandleCloseConfirmDialog} />}
      </div>
    </div>
  );
}
